import {Link} from "react-router-dom";
import {useDispatch} from "react-redux";
import {useEffect, useState} from "react";
import * as actions from "../redux/actions";
import {Button, Col, Container, Row, FormLabel} from "react-bootstrap";
import {request_post} from "../api/request";
import {LOGIN_USER} from "../api/url";
import 'react-medium-image-zoom/dist/styles.css'
import { BreadcrumbComponent } from '../component/Partials/Breadcrumb';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {useNavigate} from "react-router-dom";

export const Login = () => {
    const [validated, setValidated] = useState('');
    const [phoneError, setPhoneError] = useState('')
    const [passwordError, setPasswordError] = useState('')
    const dispatch = useDispatch();

    const loginInput = {
        phone: "",
        password: "",
      };

    const [
        { phone, password },
        setState
    ] = useState(loginInput);
    
    const clearState = () => {
        setState({ ...loginInput });
    };

    const onInputChange = e => {
        const { name, value } = e.target;

        if(e.target.name === 'phone'){
            setPhoneError('')
        }

        if(e.target.name === 'password'){
            setPasswordError('')
        }
        
        setState(prevState => ({ ...prevState, [name]: value }));
      };

    useEffect(() => {
        
    }, []);

    const onSubmitLogin = (e) => {
        //setValidated('was-validated')
        e.preventDefault();
        
        
        (phone) ? setPhoneError('') : setPhoneError('Поле не должно быть пустым');
        (password.length >= 6) ? setPasswordError(''): setPasswordError('Поле пароль не должно быть пустым или меньше 6 символа');

        (phone && password) ? login() : toast.error('Заполните форму корректно');
        
    }

    let navigate = useNavigate();

    const goToHomePage = (path) => {
        navigate(path);
    }
    
    const login =  async () => {
        
        const params = {
            phone: phone,
            password: password,
        }

        const response = await request_post(LOGIN_USER, params);

        
        if(response.success){
            //toast.success(response.data)
            setPhoneError('')
            setPasswordError('')
            clearState()
            dispatch(actions.loginToken(response.data))
            //toast.success("Вы вошли в систему")
            //setValidated([''])
            goToHomePage('/');
        }else{
            setPhoneError('Неверный телефон или пароль')
        }        
    }

    return (
        <Container>
            <Row className="mt-3">
                <Col>
                    <BreadcrumbComponent />
                </Col>
            </Row>
            
            
            <Row className="justify-content-center">
                
                <Col md="5">
                    <h3>Вход</h3>
                    <form onSubmit={onSubmitLogin}>
                        <div>
                            <FormLabel htmlFor="phone" className="form-label mt-3" >
                                Телефон <span className="text-danger">*</span>
                            </FormLabel>
                            <input value={phone} onChange={onInputChange} name="phone"
                                type="text" id="phone" className={phoneError ? 'form-control  is-invalid': 'form-control'} placeholder="Введите номер телефона" required/>
                            <div className="valid-feedback">Корректно</div>
                            <div className="invalid-feedback">{phoneError}</div>
                        </div>

                        <div>
                            <FormLabel htmlFor="password" className="form-label mt-3" >
                                Пароль <span className="text-danger">*</span>
                            </FormLabel>
                            <input value={password} onChange={onInputChange} name="password"
                                type="password" id="password" className={passwordError ? 'form-control  is-invalid': 'form-control'} placeholder="Введите пароль" required/>
                            <div className="valid-feedback">Корректно</div>
                            <div className="invalid-feedback">{passwordError}</div>
                        </div>

                        <div className="mt-3 d-flex justify-content-between">
                            <Button type="submit" >Войти</Button>
                            <Link className="btn btn-dark" to="/register">Регистрация</Link>
                        </div>
                    </form>
                </Col>
            </Row>
        </Container>
    );
}