import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import * as actions from "../redux/actions";
import {Button, Card, Col, Container, Row, FormLabel} from "react-bootstrap";
import {request_get, request_post} from "../api/request";
import {GET_CART_PRODUCTS, CREATE_ORDER} from "../api/url";
import 'react-medium-image-zoom/dist/styles.css'
import { BreadcrumbComponent } from '../component/Partials/Breadcrumb';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const Orders = () => {
    
    const dispatch = useDispatch();
    const [cartProducts, setCartProducts] = useState([]);
    const [validated, setValidated] = useState('');
    const [message, setMessage] = useState('Ваша корзина пуста');
    const [sumAmount, setSumAmount] = useState(0);
    const cartList = useSelector((state) => state.cartListReducer.cart_list_products);

    const initialState = {
        phone: "",
      };

    const [
        { phone },
        setState
    ] = useState(initialState);
    
    const clearState = () => {
        setState({ ...initialState });
    };

    const onInputChange = e => {
        
        const { name, value } = e.target;

        setState(prevState => ({ ...prevState, [name]: value }));
      };

    useEffect(() => {
        

    }, []);

    useEffect(() => {
        
        if(cartList.length){
            getCartProducts(cartList)
        }else{
            setCartProducts([])
        }
        
    }, [cartList]);

    const getCartProducts =  async (ids) => {
        const response = await request_get(GET_CART_PRODUCTS, {cart: ids.sort()}); 
        setCartProducts(response.data);
        setSumAmount(response.data.reduce((accumulator, object) => 
            accumulator + parseFloat(object.price) * object.count , 0  
        ).toFixed(2))
        
    }

    const onSubmit = () => {
        setValidated('was-validated')
        

        if(phone && sumAmount){
            createOrder();
            return;
        }
        toast.error('Заполните форму корректно');
        console.log(121)
    }
    
    const createOrder =  async () => {
        
        const params = {
            phone: phone,
            product_ids: cartList,
            currency: 'TJS',
            sumAmount: sumAmount,
        }
        const response = await request_post(CREATE_ORDER, params);

        
        if(response.success){
            toast.success(response.data)
            clearState()
            setValidated([''])
            setSumAmount(0)
            setMessage('Ваш заказ оформлен, дождитесь звонка оператора.')
            dispatch(actions.cartRemoveAll())
        }        
    }

    return (
        <Container>
            <Row className="mt-3">
                <Col>
                    <BreadcrumbComponent />
                </Col>
            </Row>
            
            <form className={validated}>
            <Row>
                <h3>Оформение Заказа</h3>
            </Row>

            <Row className="mt-3">
                <Col md="12">
                    <Card className="p-3">
                    {cartProducts.length ? 
                        <Row>
                            
                            <Col md="3">
                                <h4>Ваш заказ:</h4>

                            </Col>
                                <Col md="3">
                                    <ul>
                                        {
                                            cartProducts.map((value, index) => {
                                                return <li key={index}>{value.name} - <span className="text_price">{( value.price * value.count).toFixed(2) } TJS</span>, {value.count} Шт.
                                                </li>
                                            })
                                        }
                                        
                                    </ul>
                                </Col>

                                <Col md="6">
                                                                
                                    <Card className=" p-3" >

                                        <FormLabel htmlFor="phone" className="form-label mt-3" >
                                            Телефон <span className="text-danger">*</span>
                                        </FormLabel>
                                        <input value={phone} onChange={onInputChange} type="number" id="phone" name="phone" 
                                            className="form-control" placeholder="Введите телефон" required/>
                                        <div className="valid-feedback">Корректно</div>
                                        <div className="invalid-feedback">Поле заполнено некорректно</div>

                                        { sumAmount ?
                                            
                                            <div className="mt-2 d-flex justify-content-between">
                                                <h4>Сумма: <span className="text_price"> { sumAmount + ' '}
                                                TJS </span></h4>
                                            </div>
                                            :null
                                        }
                                        <Button onClick={onSubmit} className="mt-2 btn btn-dark pointer">Оформить заказ </Button>
                                    </Card>
                                </Col>                          
                        </Row>
                        : <p> {message} </p>}
                    </Card>
                    
                </Col>
            </Row>
            </form>
            
        </Container>
    );
}