import {useSearchParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import * as actions from "../redux/actions";
import {Button, Card, Col, Container, Row} from "react-bootstrap";
import {request_get, request_post} from "../api/request";
import {GET_PRODUCT_DETAILS_BY_ID, MAIN_URL, CREATE_COMMENT, MARK_PRODUCT, COMMENT_DELETE} from "../api/url";
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
import { BreadcrumbComponent } from '../component/Partials/Breadcrumb';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {useNavigate} from "react-router-dom";
import { HandIndexThumb, Star, StarFill, StarHalf } from "react-bootstrap-icons";

export const Product = () => {
    const [searchParams] = useSearchParams();
    const dispatch = useDispatch();
    //const show_menu_catalog = useSelector((state) => state.showMenuReducer.show_menu_catalog);
    const [product, setProduct] = useState({});
    const [commentValue, setCommentValue] = useState('');
    const [commentValueError, setCommentValueError] = useState('');
    const [stars, setStars] = useState(0);
    //const [userInfo, setUserInfo] = useState({});
    const token = useSelector((state) => state.loginTokenReducer.login_token);
    const is_admin = useSelector((state) => state.loginTokenReducer.is_admin);
    const is_logged = useSelector((state) => state.loginTokenReducer.check_user_logged);
    let navigate = useNavigate();
    useEffect(() => {
        dispatch(actions.showMenuCatalog(false))
        let product_id = searchParams.get("product_id");
        //getUserInfo(token)
        //Getting product by id
        
        getProductById(product_id);

    }, [])

    useEffect(() => {
        
    }, [stars])

    const getProductById = async (product_id) => {
        const response = await request_get(GET_PRODUCT_DETAILS_BY_ID + '/' + product_id);
        if(response.success)
        {
            
            setStars(response.data.product_sum_rate)
            setProduct(response.data)
        }
    }

    const addToCart = (product_id) => {
        dispatch(actions.cartAdd(product_id))
    }

    const commentDelete = async (comment_id) => {
        let product_id = product.id;
        const params = {
            comment_id: comment_id,
        };

        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };

        const response = await request_post(COMMENT_DELETE, params, config)
        
        if(response.success){
            toast.success('Комментарие удалено')
            getProductById(product_id)
        }
    }

    const setRate = async (rate) => {
        if(!is_logged){
            toast.error('Войдите чтобы поставить оценку!')
            return
        }
        
        const params = {
            product_id: product.id,
            rate: rate
        };

        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };

        const response = await request_post(MARK_PRODUCT, params, config)
        
        if(response.success){
            toast.success('Ваша оценка добавлена')
            getProductById(response.data)
        }
        
    }

    const createComment =  async (e) => {
        
        e.preventDefault()

        if(commentValue){
            
            setCommentValueError('')
        }else{
            
            setCommentValueError('Поле не должно быть пустым')
            return;
        }

        const params = {
            product_id: product.id,
            comment: commentValue,
        }

        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };

        const response = await request_post(CREATE_COMMENT, params, config);

        
        if(response.success){
            toast.success('Комментарие успешно добавлено');
            setCommentValue('')
            getProductById(product.id);
        }        
    }

    const goToOneClickPage = (path) => {
        
        navigate(path);
    }

    const renderProduct = () => {
        return(<div>
            <Row className="mt-3">
                <Col>
                    <BreadcrumbComponent />
                </Col>
            </Row>
            <Row>
                <Col md="6">
                    <Container>
                        <Row className="justify-content-center">
                            <Col md="6" className="d-flex justify-content-center">
                                <Zoom>
                                    <img
                                        alt="that wanaka tree"
                                        src={`${MAIN_URL}images/products/${product.img}`}
                                        width="200"
                                    />
                                </Zoom>
                            </Col>
                        </Row>
                        <Row xs="auto" className="d-flex justify-content-center">
                            {
                                product.images.length > 0 ?
                                    product.images.map((value, index) => {
                                        return <Col key={index}>
                                        <Card  style={{width: '6rem'}}>
                                            <Zoom>
                                                    <Card.Img style={{width: '6rem'}} variant="" src={`${MAIN_URL}images/products/${value.img}`} />
                                            </Zoom>
                                                </Card>
                                        </Col>

                                    })
                                     : null
                            }

                        </Row>
                    </Container>
                </Col>
                <Col md="6">
                    <h4>{product.name}</h4>
                    <hr />
                    
                    {
                        stars === 1 ? 
                        <div className="pointer" title="Поставить оценку"> 
                            <StarFill color="#ffd814" className="star-margin" onClick={() => setRate(1)}/> 
                            <Star color="#ffd814" className="star-margin" onClick={() => setRate(2)}/> 
                            <Star color="#ffd814" className="star-margin" onClick={() => setRate(3)}/> 
                            <Star color="#ffd814" className="star-margin" onClick={() => setRate(4)}/> 
                            <Star color="#ffd814" onClick={() => setRate(5)}/>
                        </div>
                        : null
                    }

                    {
                        stars === 1.5 ? 
                            <div className="pointer" title="Поставить оценку"> 
                                <StarFill color="#ffd814" className="star-margin" onClick={() => setRate(1)}/> 
                                <StarHalf color="#ffd814" className="star-margin" onClick={() => setRate(2)}/> 
                                <Star color="#ffd814" className="star-margin" onClick={() => setRate(3)}/> 
                                <Star color="#ffd814" className="star-margin" onClick={() => setRate(4)}/> 
                                <Star color="#ffd814" onClick={() => setRate(5)}/>
                            </div>
                        : null
                    }

                    {
                        stars === 2 ? 
                            <div className="pointer" title="Поставить оценку"> 
                                <StarFill color="#ffd814" className="star-margin" onClick={() => setRate(1)}/> 
                                <StarFill color="#ffd814" className="star-margin" onClick={() => setRate(2)}/> 
                                <Star color="#ffd814" className="star-margin" onClick={() => setRate(3)}/> 
                                <Star color="#ffd814" className="star-margin" onClick={() => setRate(4)}/> 
                                <Star color="#ffd814" onClick={() => setRate(5)}/>
                            </div>
                        : null
                    }

                    {
                        stars === 2.5 ? 
                            <div className="pointer" title="Поставить оценку"> 
                                <StarFill color="#ffd814" className="star-margin" onClick={() => setRate(1)}/> 
                                <StarFill color="#ffd814" className="star-margin" onClick={() => setRate(2)}/> 
                                <StarHalf color="#ffd814" className="star-margin" onClick={() => setRate(3)}/> 
                                <Star color="#ffd814" className="star-margin" onClick={() => setRate(4)}/> 
                                <Star color="#ffd814" onClick={() => setRate(5)}/>
                            </div>
                        : null
                    }

                    {
                        stars === 3 ? 
                            <div className="pointer" title="Поставить оценку"> 
                                <StarFill color="#ffd814" className="star-margin" onClick={() => setRate(1)}/> 
                                <StarFill color="#ffd814" className="star-margin" onClick={() => setRate(2)}/> 
                                <StarFill color="#ffd814" className="star-margin" onClick={() => setRate(3)}/> 
                                <Star color="#ffd814" className="star-margin" onClick={() => setRate(4)}/> 
                                <Star color="#ffd814" onClick={() => setRate(5)}/>
                            </div>
                        : null
                    }

                    {
                        stars === 3.5 ? 
                            <div className="pointer" title="Поставить оценку"> 
                                <StarFill color="#ffd814" className="star-margin" onClick={() => setRate(1)}/> 
                                <StarFill color="#ffd814" className="star-margin" onClick={() => setRate(2)}/> 
                                <StarFill color="#ffd814" className="star-margin" onClick={() => setRate(3)}/> 
                                <StarHalf color="#ffd814" className="star-margin" onClick={() => setRate(4)}/> 
                                <Star color="#ffd814" onClick={() => setRate(5)}/>
                            </div>
                        : null
                    }

                    {
                        stars === 4 ? 
                            <div className="pointer" title="Поставить оценку"> 
                                <StarFill color="#ffd814" className="star-margin" onClick={() => setRate(1)}/> 
                                <StarFill color="#ffd814" className="star-margin" onClick={() => setRate(2)}/> 
                                <StarFill color="#ffd814" className="star-margin" onClick={() => setRate(3)}/> 
                                <StarFill color="#ffd814" className="star-margin" onClick={() => setRate(4)}/> 
                                <Star color="#ffd814" onClick={() => setRate(5)}/>
                            </div>
                        : null
                    }

                    {
                        stars === 4.5 ? 
                            <div className="pointer" title="Поставить оценку"> 
                                <StarFill color="#ffd814" className="star-margin" onClick={() => setRate(1)}/> 
                                <StarFill color="#ffd814" className="star-margin" onClick={() => setRate(2)}/> 
                                <StarFill color="#ffd814" className="star-margin" onClick={() => setRate(3)}/> 
                                <StarFill color="#ffd814" className="star-margin" onClick={() => setRate(4)}/> 
                                <StarHalf color="#ffd814" onClick={() => setRate(5)}/>
                            </div>
                        : null
                    }

                    {
                        stars === 5 ? 
                            <div className="pointer" title="Поставить оценку"> 
                                <StarFill color="#ffd814" className="star-margin" onClick={() => setRate(1)}/> 
                                <StarFill color="#ffd814" className="star-margin" onClick={() => setRate(2)}/> 
                                <StarFill color="#ffd814" className="star-margin" onClick={() => setRate(3)}/> 
                                <StarFill color="#ffd814" className="star-margin" onClick={() => setRate(4)}/> 
                                <StarFill color="#ffd814" onClick={() => setRate(5)}/>
                            </div>
                        : null
                    }

                    {
                        stars === 0 ? 
                        <div className="pointer" title="Поставить оценку"> 
                            <Star color="#ffd814" className="star-margin" onClick={() => setRate(1)}/> 
                            <Star color="#ffd814" className="star-margin" onClick={() => setRate(2)}/> 
                            <Star color="#ffd814" className="star-margin" onClick={() => setRate(3)}/> 
                            <Star color="#ffd814" className="star-margin" onClick={() => setRate(4)}/> 
                            <Star color="#ffd814" onClick={() => setRate(5)}/>
                        </div>
                        : null
                    }
                    
                    <p>Цена: <span  className="text_price"> {product.price} сом </span></p>
                    {
                        product.old_price ?
                            <p>Старая цена: <span className="old_price"> {product.old_price} сом</span></p>
                        :null
                    }
                    <div className="d-flex justify-content-between">
                        <div>
                            <Button size="lg" onClick={() => addToCart(product.id)} className="cart-button-product pointer" >Добавить в корзину</Button>
                        </div>
                        <div>
                            <Button  size="lg" variant="dark pointer" onClick={() => goToOneClickPage('/one_click_buy?product_id='+product.id)}>
                                <HandIndexThumb/>
                            </Button>
                        </div>
                        
                    </div>
                    
                    {product.brands ?
                        <div>
                            <hr/>
                            <p>Бренд: <span > {product.brands.name}</span></p> 
                        </div>
                        
                        : 
                    null}
                    
                    <hr />
                    <ul className="list-unstyled">

                    {
                        product.details.length > 0 ?
                            product.details.map((value, index) => {
                                return <li key={index}>
                                    {value.name}: <span className="text-primary"> {value.value}</span>
                                </li>
                            })
                            :null

                    }
                    </ul>

                </Col>
            </Row>
            {
                
                <Row className="mt-3">
                    <Col md="12">

                    <Tabs
                        defaultActiveKey="description"
                        id="uncontrolled-tab-example"
                        className="mb-3"
                        >
                        <Tab eventKey="description" title="Описание">
                            {product.description ? 
                            
                            <p dangerouslySetInnerHTML={{ __html: product.description }}></p>
                            :null}
                        </Tab>
                        <Tab eventKey="comments" title="Комментарии">
                            <h3>Комментарии</h3>
                            {product.comments ? 
                                product.comments.map((item, key) => {
                                    return <Card key={key} className="mt-3 mb-3">
                                    <Card.Header>
                                        <div className="d-flex justify-content-between">
                                            <div>
                                                <p>{item.created_at}</p>
                                            </div>
                                            <div>
                                                <p>{item.user.name}</p>
                                            </div>
                                        </div>
                                        
                                    </Card.Header>
                                    <Card.Body className="m-3">
                                        <div className="d-flex justify-content-between">
                                            <div>
                                                <p>{item.name}</p>
                                            </div>
                                            
                                            {is_admin ? 
                                            <div>
                                                <Button variant="danger" onClick={() => commentDelete(item.id)}>Удалить</Button>
                                            </div>
                                            : null}
                                            
                                        </div>
                                    </Card.Body>
                                </Card>
                                })
                            :null}
                            
                            
                            {token ?

                                <form className="mt-2" onSubmit={createComment}>
                                    <h4>Добавить комментарию</h4>    
                                    <textarea required value={commentValue} onChange={e => setCommentValue(e.target.value) } 
                                        type="text" className={commentValueError ? 'form-control comment-textarea is-invalid' : 'comment-textarea form-control'} rows="6" ></textarea>
                                    <Button type="submit" className="mt-3">Добавить</Button>
                                    
                                    <div className="invalid-feedback">{commentValueError}</div>
                                </form>
                             : <h6 className="text-danger">Зарегистрируйтесь, что бы оставить комментарий!</h6>}
                            
                        </Tab>
                    </Tabs>
                    </Col>
                </Row>
                
            }
            
        </div>
            

            
        )
    }


    return (
        <Container>
            {
                product // 👈 null and undefined check
                && Object.keys(product).length !== 0 ?
                    renderProduct()
                    : null
            }
        </Container>  
            
    );
}
