//import {request_get} from '../api/request';
import {
    SHOW_MENU_CATALOG,
    CART_ADD_PRODUCT,
    CART_REMOVE_ONE_PRODUCT,
    CART_REMOVE_SAME_PRODUCTS,
    CART_REMOVE_ALL_PRODUCTS,
    SEARCH_PRODUCTS,
    LOGIN_TOKEN,
    CHECK_USER_LOGGED,
    LOGOUT,
    SHOW_MODAL,
    IS_ADMIN,
} from './constants';

export const showMenuCatalog = (state) => ({
    type: SHOW_MENU_CATALOG,
    payload: state,
});

export const cartAdd = (state) => ({
    type: CART_ADD_PRODUCT,
    payload: state,
});

export const cartRemoveOne = (state) => ({
    type: CART_REMOVE_ONE_PRODUCT,
    payload: state,
});

export const cartRemoveSame = (state) => ({
    type: CART_REMOVE_SAME_PRODUCTS,
    payload: state,
});

export const cartRemoveAll = (state) => ({
    type: CART_REMOVE_ALL_PRODUCTS,
    payload: state,
});

export const searchProducts = (state) => ({
    type: SEARCH_PRODUCTS,
    payload: state,
});

export const loginToken = (state) => ({
    type: LOGIN_TOKEN,
    payload: state,
});

export const loginCheck = (state) => ({
    type: CHECK_USER_LOGGED,
    payload: state,
});

export const logout = (state) => ({
    type: LOGOUT,
    payload: state,
});

export const isAdmin = (state) => ({
    type: IS_ADMIN,
    payload: state,
});
/*
export const setSid = (sid) => ({
    type: SET_SID,
    payload: sid,
});

export const setNavigationVisibility = (val) => ({
    type: SET_NAVIGATION_VISIBILITY,
    payload: val,
});

export const setHistory = (history) => ({
    type: SET_HISTORY,
    payload: history,
});

export const selectMusic = (music) => ({
    type: SELECT_MUSIC,
    payload: music,
});

export const setLoader = (loading) => ({
    type: SET_LOADER,
    payload: loading,
});

export const setSearchText = (text) => ({
    type: SET_SEACTH_TEXT,
    payload: text,
});

export const setFavoritesMusic = (musics) => ({
    type: SET_FAVORITES_MUSIC,
    payload: musics,
});

export const getHistory = (sid) => {
    return (dispatch) => {
        request_get(`vod_data_history/${sid}`).then(({data}) => {
            dispatch(setHistory(data));
        });
    };
};

export const getFavoritesMusic = (sid, searchText = '') => {
    return (dispatch) => {
        dispatch(setLoader(true));
        request_get(`musics_favorite/${sid}`)
            .then(({data, success}) => {
                if (success) {
                    const vodfiles = data
                        .map((music) => ({
                            id: music.id,
                            music: music.music_vodfiles,
                            poster: music.poster,
                            genr: music.genr,
                        }))
                        .filter((item) => item.music.file_torrent.includes(searchText));
                    dispatch(setFavoritesMusic(vodfiles));
                } else {
                    dispatch(setFavoritesMusic([]));
                }
            })
            .finally(() => dispatch(setLoader(false)));
    };
};*/
