import './App.css';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import { Header } from './component/Partials/Header';
import {Catalog} from "./component/Partials/Catalog";

import {applyMiddleware, createStore} from 'redux';
import { Provider } from 'react-redux'
import reducers from "./redux/reducers";
import thunk from 'redux-thunk';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {Invoices} from "./routes/invoices";
import {Carts} from "./routes/carts";
import {Main} from "./component/Main";
import {Footer} from "./component/Partials/Footer";
import {Product} from "./routes/product";
import {Login} from "./routes/Login";
import {OneClickBuy} from "./routes/OneClickBuy";
import {LoginMerchant} from "./routes/LoginMerchant";
import {SubCategory} from "./routes/SubCategory";
import {SearchProducts} from "./routes/SearchProducts";
import {Orders} from "./routes/orders";
import { useEffect }  from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Register } from './routes/Register';
import { Profile } from './routes/Profile';
import { TypeProducts } from './routes/TypeProducts';
import { Faqs } from './routes/Faqs';
import { Contacts } from './routes/Contacts';


const store = createStore(reducers, applyMiddleware(thunk));

function App() {

    useEffect(() => {
    }, [])

    return (
        
        <Provider store={store}>
            <BrowserRouter>
                <Header />
                <Catalog  />
                <Routes>
                    <Route path="/" element={<Main /> }  />
                    <Route path="invoices" element={<Invoices />} />
                    <Route path="sub_category" element={<SubCategory />} />
                    <Route path="/sub_category/product" element={<Product />} />
                    <Route path="/carts"  element={<Carts />} />
                    <Route path="/orders"  element={<Orders />} />
                    <Route path="/search"  element={<SearchProducts />} />
                    <Route path="/login"  element={<Login />} />
                    <Route path="/login-merchant"  element={<LoginMerchant />} />
                    <Route path="/register"  element={<Register />} />
                    <Route path="/profile"  element={<Profile />} />
                    <Route path="/one_click_buy"  element={<OneClickBuy />} />
                    <Route path="/type_products"  element={<TypeProducts />} />
                    <Route path="/faqs"  element={<Faqs />} />
                    <Route path="/contacts"  element={<Contacts />} />
                </Routes>
                <Footer/>
            </BrowserRouter>
            

        </Provider>
        
            
        
      
  );
}

export default App;
