import {Link, useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import * as actions from "../redux/actions";
import {Badge, Button, Card, Col, Container, Row} from "react-bootstrap";
import {request_get} from "../api/request";
import {GET_SUB_PRODUCTS, MAIN_URL, GET_MIN_MAX, GET_PRODUCTS_BRANDS, GET_PRODUCTS_DETAILS} from "../api/url";
import Pagination from "react-js-pagination";
import { useSearchParams  } from "react-router-dom";
import { BreadcrumbComponent } from '../component/Partials/Breadcrumb';
import ReactSlider from 'react-slider'
import { Dash, HandIndexThumb, Plus } from "react-bootstrap-icons";

export const SubCategory = () => {

    const [searchParams, setSearchParams] = useSearchParams();
    let sub_category_id = searchParams.get("sub_category_id");
    
    let options = {sub_category_id: sub_category_id};
    let navigate = useNavigate();

    const dispatch = useDispatch();
    const show_menu_catalog = useSelector((state) => state.showMenuReducer.show_menu_catalog);
    //const cart_add = useSelector((state) => state.cartListReducer.cart_list_products);

    const [listProducts, setListProducts] = useState([]);
    const [filterShow, setFilterShow] = useState(false);

    const [paginateResponse, setPaginateResponse] = useState('');

    const [startPrice, setStartPrice] = useState(0);
    const [endPrice, setEndPrice] = useState(0);
    const [minPrice, setMinPrice] = useState(0);
    const [maxPrice, setMaxPrice] = useState(0);

    const [listBrands, setListBrands] = useState([]);
    const [defaultBrands, setDefaultBrands] = useState([]);
    const [defaultDetails, setDefaultDetails] = useState([]);
    const [filtering, setFiltering] = useState(false);
    const [params, setParams] = useState({brands:[], details:[]})

    useEffect(() => {
        dispatch(actions.showMenuCatalog(false))
        getProductsBySubCategory();
        
        getMinMaxPrice();
        getBrands();
        getDetails();

    }, [sub_category_id]);

    useEffect(() => {
        
        if(filtering){
            filteredProductsBySubCategory();
        }
        
    }, [params]);

    useEffect(() => {
        

    }, []);

    const getProductsBySubCategory =  async (pageNumber = 1) => {
        dispatch(actions.showMenuCatalog(false))
        const response = await request_get(GET_SUB_PRODUCTS+'?page=' + pageNumber + '&sub_category_id='+ sub_category_id, params); 
        setListProducts(response.data);
        setPaginateResponse(response);
    }

    const filteredProductsBySubCategory =  async (pageNumber = 1) => {
        const response = await request_get(GET_SUB_PRODUCTS+'?page=' + pageNumber + '&sub_category_id='+ sub_category_id,  params); 
        setListProducts(response.data);
        let prices = []

        response.data.forEach(element => {
            prices.push(element.products[0].price)
        });
        setPaginateResponse(response)
    }

    const goToProductPage = (path) => {
        navigate(path);
    }

    const addToCart = (product_id) => {
        dispatch(actions.cartAdd(product_id))
    }

    const getMinMaxPrice =  async () => {
        const response = await request_get(GET_MIN_MAX+'/' + sub_category_id); 
        
        setStartPrice(parseFloat(response.data.min))
        setEndPrice(parseFloat(response.data.max))
        setMinPrice(parseFloat(response.data.min))
        setMaxPrice(parseFloat(response.data.max))
    }

    const getBrands =  async () => {
        const response = await request_get(GET_PRODUCTS_BRANDS+'/' + sub_category_id); 
        
        setDefaultBrands(response.data)
        
    }

    const getDetails =  async () => {
        const response = await request_get(GET_PRODUCTS_DETAILS+'/' + sub_category_id); 
        
        setDefaultDetails(response.data)
        
    }

    const onChangePrice = (e) => {
        setMinPrice(e[0])
        setMaxPrice(e[1])
    }

    const onAfterChangePrice = (e) => {
        
        setFiltering(true);
        options.minPrice = e[0]
        options.maxPrice = e[1]
        setParams(prevValue => ({
            ...prevValue,
            ['minPrice'] : e[0],
            ['maxPrice']: e[1]
        }) )
    }

    const filterByBrands = (e) => {
        
        setFiltering(true)
        if(e.target.checked){
            setParams(prevState => ({
                ...prevState,
                brands: [...prevState.brands, e.target.value]
             }));
            
        }else{
            
            setParams(current => {
                
                const brands = {...current};
                
                let filteredArray = brands.brands.filter(function(el) { return el !== e.target.value })
                
                brands.brands = filteredArray;
                
                return brands;
              });
        }
        
    }

    const filterByDetails = (e) => {
        setFiltering(true)

        if(e.target.checked){
            setParams(prevState => ({
                ...prevState,
                details: [...prevState.details, e.target.value + ',' + e.target.id]
            }));
        }else{
            
            setParams(current => {
                
                const details = {...current};
                
                let filteredArray = details.details.filter(function(el) { return el !== e.target.value + ',' + e.target.id })
                
                details.details = filteredArray;
                
                return details;
              });
        }
        
    }

    const goToOneClickPage = (path) => {
        navigate(path);
    }

    return (
        <Container>
            <Row className="mt-3">
                <Col>
                    <BreadcrumbComponent />
                </Col>
            </Row>

            <Row >
                <Col md="2" className="mb-3" >
                 
                <label className="form-label">Цена</label>
                <ReactSlider
                    className="horizontal-slider"
                    thumbClassName="example-thumb"
                    trackClassName="example-track"
                    //defaultValue={[0, 6000]}
                    value={[minPrice, maxPrice]}
                    min={startPrice}
                    max={endPrice}
                    ariaLabel={['Lower thumb', 'Upper thumb']}
                    ariaValuetext={state => `Thumb value ${state.valueNow}`}
                    renderThumb={(props, state) => <div {...props}>{state.valueNow}</div>}
                    //pearling
                    onChange={onChangePrice}
                    //minDistance={10}
                    onAfterChange={onAfterChangePrice}
                    /*min={0}
      max={endPrice}
                    value={[120, endPrice]}
                    className="horizontal-slider"
    thumbClassName="example-thumb"
    trackClassName="example-track"
    renderThumb={(props, state) => <div {...props}>{state.valueNow}</div>}*/
                />
                        
                        <Button className="mt-2 filter-show" onClick={() => setFilterShow(!filterShow)}>
                            
                            Фильтр {filterShow ? 
                            <Dash />  : <Plus />
                        }
                        </Button>
                
                        {
                            defaultBrands.length ?
                            <Card className={filterShow ? "mt-2" : "mt-2 hide-filter-mobile" }>
                                <div className="m-2">
                                    <h6 className="text-secondary">Бренды</h6>{
                                        defaultBrands.map((value, key) => {
                                            //const brand = value.products[0].brands
                                            return <p className="mb-0" key={key}>{value.name}   
                                            <input name="brands[]" onClick={filterByBrands} type="checkbox" value={value.id} className="float-end" /></p>
                                        })
                                    }
                                    </div>  
                                </Card>
                                :null
                        }
                    

                        {
                            defaultDetails.length > 0 ?
                                defaultDetails.map((value, key) => {
                                    const details = Object.keys(value)
                                    return details.map((item, i) => {
                                        return <Card className={filterShow ? "mt-2" : "mt-2 hide-filter-mobile" } key={i}>
                                                    <div className="m-2" >
                                                        <h6 className="text-secondary">{item}</h6>
                                                        
                                                        {
                                                            value[item].map((bval, bkey) => {
                                                                
                                                                return <p className="mb-0" key={bkey}> {bval} 
                                                                        <input id={item} name={`details[${item}]`} onClick={filterByDetails} type="checkbox" value={bval} className="float-end" />
                                                                        
                                                                    </p>;
                                                            })
                                                        }
                                                    </div>  
                                                </Card>
                                    })
                                })
                                :null
                        }
               
                </Col>
                <Col md="10">
                    <Row className="">
                    {
                        listProducts.length > 0 ?
                            listProducts.map((value, index) => {
                                    return  <Col md="3" key={index} className="mob-card width-20"> 
                                    <Card className="card_product">
                                    <Card.Img className="img-fluid product_img_main" onClick={() => 
                                        goToProductPage('/sub_category/product?product_id='+value.products[0].id+'&sub_category_id='+value.sub_category_id)
                                        } 
                                        variant="bottom" src={MAIN_URL + 'images/products/'+value.products[0].img} />
                                    <Card.Body>
                                    <Link className="link-cart" to={'/sub_category/product?product_id='+value.products[0].id+'&sub_category_id='+value.sub_category_id}>
                                        {value.products[0].discount != null ? <Badge bg={"danger"}>{value.products[0].discount}%</Badge> : null }

                                        <Card.Title className="card_title"><span className="text_price">{value.products[0].price} сом </span>
                                            {value.products[0].old_price != null ? <span className="old_price">{value.products[0].old_price} сом</span> : null }
                                        </Card.Title>

                                        <Card.Text>
                                            {value.products[0].name.length > 40 ? value.products[0].name.slice(0,40)+ "..." : value.products[0].name}
                                            
                                        </Card.Text>
                                        
                                        </Link>
                                        
                                    </Card.Body>
                                    <div className="d-flex justify-content-between">
                                        
                                            <Button title="Добавить в корзину" onClick={() => addToCart(value.products[0].id)} className="addToCard pointer" >В корзину</Button>
                                        
                                            <Button title="Купить в один клик" variant="dark addToCard pointer one-click" onClick={() => goToOneClickPage('/one_click_buy?product_id='+value.products[0].id)}>
                                                <HandIndexThumb/>
                                            </Button>
                                        
                                        
                                    </div>
                                </Card>
                                    </Col>
                                    
                                

                            }) : null
                    }
                    </Row>
                </Col>
            </Row>

            <Row xs="auto" className="d-flex justify-content-center">
                <Col>
                <Pagination
                    activePage={paginateResponse.current_page ? paginateResponse.current_page : 0}
                    itemsCountPerPage={paginateResponse.per_page ? paginateResponse.per_page : 0 }
                    totalItemsCount={paginateResponse.total ? paginateResponse.total : 0}
                    onChange={(pageNumber) => {
                        getProductsBySubCategory(pageNumber)
                    }}
                    pageRangeDisplayed={14}
                    itemClass="page-item"
                    linkClass="page-link"
                    firstPageText="Пред. страница"
                    lastPageText="След. страница"
                />
                </Col>
            </Row>

        </Container>
    );
}
