import {SHOW_MENU_CATALOG} from './constants';

const initialState = {
    show_menu_catalog: false,
};

const showMenuReducer = (state = initialState, action) => {
    switch (action.type) {
        case SHOW_MENU_CATALOG:
            return {
                ...state,
                show_menu_catalog: action.payload,
            };
        default:
            return state;
    }

    
};

export default showMenuReducer;
