import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import * as actions from "../redux/actions";
import {Button, Col, Container, Row, FormLabel} from "react-bootstrap";
import {request_post} from "../api/request";
import {USER_INFO, USER_UPDATE} from "../api/url";
import 'react-medium-image-zoom/dist/styles.css'
import { BreadcrumbComponent } from '../component/Partials/Breadcrumb';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {useNavigate} from "react-router-dom";

export const Profile = () => {
    const [validated, setValidated] = useState('');
    const [phoneError, setPhoneError] = useState('')
    const [nameError, setNameError] = useState('')
    const [emailError, setEmailError] = useState('')
    const dispatch = useDispatch();
    //const check_user = useSelector((state) => state.loginTokenReducer.check_user_logged);
    const token = useSelector((state) => state.loginTokenReducer.login_token);

    const [userInfo, setUserInfo] = useState({phone: "", name: "", email:""});

    /*const [
        { phone, email, name },
        setState
    ] = useState(loginInput);*/
    
    /*const clearState = () => {
        setState({ ...loginInput });
    };*/

    const onInputChange = e => {
        const { name, value } = e.target;

        if(e.target.name === 'phone'){
            setPhoneError('')
        }

        if(e.target.name === 'name'){
            setNameError('')
        }

        if(e.target.name === 'email'){
            setEmailError('')
        }
        
        setUserInfo(prevState => ({ ...prevState, [name]: value }));
      };

    useEffect(() => {
        
    }, []);

    useEffect(() => {
        getUserInfo(token)
        dispatch(actions.showMenuCatalog(false))
    }, []);

    const onSubmit = () => {
        //setValidated('was-validated')
        //console.log('phone', phone);
        (userInfo.phone) ? setPhoneError('') : setPhoneError('Поле не должно быть пустым');
        (userInfo.email) ? setEmailError('') : setEmailError('Поле не должно быть пустым');
        (userInfo.name) ? setNameError('') : setNameError('Поле не должно быть пустым');

        (userInfo.phone && userInfo.email && userInfo.name) ? updateUser() : toast.error('Заполните форму корректно');
        
    }

    const getUserInfo = async (token) => {
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };
        
        const response = await request_post(USER_INFO, {}, config);
        
        if(response.success){
            setUserInfo(response.data)
        }
        
        //return response
    }

    let navigate = useNavigate();

    const goToHomePage = (path) => {
        navigate(path);
    }

    const logOut = async () => {
        dispatch(actions.logout())
        goToHomePage('/')
        
        //return response
    }
    
    const updateUser =  async () => {
        
        const data = userInfo

        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };
        
        const response = await request_post(USER_UPDATE, data, config);

        
        if(response.success){
            //toast.success(response.data)
            setPhoneError('')
            setNameError('')
            setEmailError('')
            //clearState()
            toast.success("Данные успешно сохранены")
            //setValidated([''])
            
        }        
    }

    return (
        <Container>
            <Row className="mt-3">
                <Col>
                    <BreadcrumbComponent />
                </Col>
            </Row>
            <ToastContainer 
                position="top-right"
                autoClose={4000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme = "colored"
            />
            
            {userInfo ? 
            <form className={validated}>
                <Row >
                
                    <Col md="5">
                        <h3>Профиль</h3>    
                        <div>
                            <FormLabel htmlFor="name" className="form-label mt-3" >
                                Имя <span className="text-danger">*</span>
                            </FormLabel> 
                            <input value={userInfo.name} onChange={onInputChange} name="name"
                                type="name" id="name" className={nameError ? 'form-control  is-invalid': 'form-control'} 
                                placeholder="Введите имя" required/>
                            <div className="valid-feedback">Корректно</div>
                            <div className="invalid-feedback">{nameError}</div>
                        </div>

                        <div>
                            <FormLabel htmlFor="phone" className="form-label mt-3" >
                                Телефон <span className="text-danger">*</span>
                            </FormLabel> 
                            <input value={userInfo.phone} onChange={onInputChange} name="phone"
                                type="text" id="phone" className={phoneError ? 'form-control  is-invalid': 'form-control'} 
                                placeholder="Введите номер телефона" required/>
                            <div className="valid-feedback">Корректно</div>
                            <div className="invalid-feedback">{phoneError}</div>
                        </div>

                        <div>
                            <FormLabel htmlFor="email" className="form-label mt-3" >
                                Email <span className="text-danger">*</span>
                            </FormLabel> 
                            <input value={userInfo.email} onChange={onInputChange} name="email"
                                type="email" id="email" className={emailError ? 'form-control  is-invalid': 'form-control'} 
                                placeholder="Введите email" required/>
                            <div className="valid-feedback">Корректно</div>
                            <div className="invalid-feedback">{emailError}</div>
                        </div>

                        <div className="mt-3 d-flex justify-content-between">
                            <Button onClick={onSubmit}>Сохранить</Button>
                            <Button className="btn btn-dark" onClick={logOut}>Выйти</Button>
                        </div>
                        
                    </Col>

                </Row>
            
            </form>
            : null
            }
            
            
        </Container>
    );
}