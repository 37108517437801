/*export const SET_AUTH_STATE = 'SET_AUTH_STATE';
export const SET_SID = 'SET_SID';
export const SET_NAVIGATION_VISIBILITY = 'SET_NAVIGATION_VISIBILITY';

export const SET_HISTORY = 'SET_HISTORY';
export const SET_FAVORITES_MUSIC = 'SET_FAVORITES_MUSIC';
export const SET_LOADER = 'SET_LOADER';
export const SELECT_MUSIC = 'SELECT_MUSIC';
export const SET_SEACTH_TEXT = 'SET_SEACTH_TEXT';*/

export const SHOW_MENU_CATALOG = 'SHOW_MENU_CATALOG';
export const CART_ADD_PRODUCT = 'CART_ADD_PRODUCT';
export const CART_REMOVE_ONE_PRODUCT = 'CART_REMOVE_ONE_PRODUCT';
export const CART_REMOVE_SAME_PRODUCTS = 'CART_REMOVE_SAME_PRODUCTS';
export const CART_REMOVE_ALL_PRODUCTS = 'CART_REMOVE_ALL_PRODUCTS';
export const SEARCH_PRODUCTS = 'SEARCH_PRODUCTS';
export const LOGIN_TOKEN = 'LOGIN_TOKEN';
export const CHECK_USER_LOGGED = 'CHECK_USER_LOGGED';
export const LOGOUT = 'LOGOUT';
export const SHOW_MODAL = 'SHOW_MODAL';
export const IS_ADMIN = 'IS_ADMIN';