import {useDispatch, useSelector} from "react-redux";
import * as actions from "../redux/actions";
import {useEffect} from "react";


export const Invoices = () => {
    const dispatch = useDispatch();
    const show_menu_catalog = useSelector((state) => state.showMenuReducer.show_menu_catalog);
    useEffect(() => {
        dispatch(actions.showMenuCatalog(false))

    }, [])
    return (
        <h1>Invoices</h1>
    )
}
