import {CART_ADD_PRODUCT, CART_REMOVE_ONE_PRODUCT, CART_REMOVE_SAME_PRODUCTS, CART_REMOVE_ALL_PRODUCTS} from './constants';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const items = localStorage.getItem('carts') || null;


const initialState = {
    cart_list_products: items ? JSON.parse(items) : []
};

const cartListReducer = (state = initialState, action) => {
    switch (action.type) {
        case CART_ADD_PRODUCT:
            
            if(state.cart_list_products.length >24){
                toast.error("Нельзя добавить больше 25 товаров.")    
                return state
            }
            toast.success("Товар добавлен в корзину.")
            state = {
                ...state,
                cart_list_products: [ ...state.cart_list_products, action.payload],
            };
            
            break;
        case CART_REMOVE_ONE_PRODUCT:
            
            const cartArray = state.cart_list_products;
            let index = cartArray.indexOf(action.payload);
            
            if(index >= 0){
                cartArray.splice(index, 1);
            }
            toast.success("Товар удален с корзины.")
            state = {
                ...state,
                cart_list_products:  [...cartArray]
            };
            break;
        case CART_REMOVE_SAME_PRODUCTS:
            const cartArr = state.cart_list_products;
            const newArr = cartArr.filter(function(el) { return el !== action.payload })
            
            toast.success("Товары удалены с корзины.")
            state = {
                ...state,
                cart_list_products:  [...newArr]
            };

            break;
        case CART_REMOVE_ALL_PRODUCTS:
            //toast.success("Корзина очищена.")
            state = {
                ...state,
                cart_list_products:  []
            };
            break;
        
    }
    localStorage.setItem('carts', JSON.stringify(state.cart_list_products))
    return state;
    
};

export default cartListReducer;
