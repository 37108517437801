import {Col, Container, Row, Accordion} from "react-bootstrap";
import 'react-medium-image-zoom/dist/styles.css'
import { BreadcrumbComponent } from '../component/Partials/Breadcrumb';

export const Faqs = () => {
    return (
        <Container>
            <Row className="mt-3">
                <Col>
                    <BreadcrumbComponent />
                </Col>
            </Row>
            
            <div className="justify-content-center text-center">
                
                <h3>ЧаВо</h3>

                
                    <Accordion >
                        <Accordion.Item eventKey="1" className="mb-2">
                            <Accordion.Header>Как купить товар и оформить заказ на сайте?</Accordion.Header>
                            <Accordion.Body className="text-start">
                                Это очень просто. Вы можете просмотреть каталог товаров и перенести выбранный вами товар в корзину. 
                                Затем, вам нужно перейти в корзину и указать контактные данные, выбрать метод оплаты и доставки После ввода 
                                данных карты, вы подтверждаете заказ и оплату. При подтверждении заказа, вы можете ознакомится с условиями 
                                покупки товара. После оплаты заказа, вам на почту или СМС-оповещением придет электронный чек.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2" className="mb-2">
                            <Accordion.Header>Требуется ли регистрация на сайте для оформления заказа?</Accordion.Header>
                            <Accordion.Body className="text-start">
                                Нет не требуется. Вы можете зарегистрироваться по желанию для получения новостей и акций от Hayass магазина.
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="3" className="mb-2">
                            <Accordion.Header>Какая сумма минимального заказа?</Accordion.Header>
                            <Accordion.Body className="text-start">
                                Минимальный заказ – 100 сомони.
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="4" className="mb-2">
                            <Accordion.Header>Могу ли я вернуть купленный товар?</Accordion.Header>
                            <Accordion.Body className="text-start">
                                Товар можно вернуть для обмена на другой товар. Обмен товара можно оформить в течение 10 дней при соблюдении следующих обязательный условий:
                                <br/>
                                <br/>
                                ✔ товар не был в употреблении
                                <br/>
                                ✔ товар не имеет физических повреждений
                                <br/>
                                ✔ сохранена оригинальная упаковка товара
                                <br/>
                                ✔ товарный чек и прочие документы в наличии.
                                <br/>
                                <br/>
                                Товар нужно вернуть для обмена в указанный пункт выдачи товаров или же в торговых точках наших партнёров.
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="5" className="mb-2">
                            <Accordion.Header>Кому обратится с предложением, жалобами и вопросами?</Accordion.Header>
                            <Accordion.Body className="text-start">
                                Вы можете обратиться к нашему онлайн консультанту или же написать на адрес электронной почты admin@gmail.com или же позвонив по номеру +992 90 100 05 35
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="6" className="mb-2">
                            <Accordion.Header>Что нам делать если не получили товар в течение оговоренного срока?</Accordion.Header>
                            <Accordion.Body className="text-start">
                                Если по какой-то причине товар не был доставлен, вы можете обратиться к нам в любое удобное для вас время. 
                                Мы гарантируем быстрое решение подобных вопросов. В противном случае, вы имеете право обратиться в Первый 
                                Микрофинансовый Банк для возврата суммы купленного товара. Банк, после проверки документов и фактов не 
                                доставления товара, возвращает вам сумму купленного товара в рамках банковских правил и процедур.
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
            </div>
        </Container>
    );
}