import {SEARCH_PRODUCTS} from './constants';

const initialState = {
    search_products: '',
};

const searchProducts = (state = initialState, action) => {
    
    switch (action.type) {
        
        case SEARCH_PRODUCTS:
            return {
                ...state,
                search_products: action.payload,
            };
        default:
            return state;
    }

    
};

export default searchProducts;
