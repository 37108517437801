import {useDispatch} from "react-redux";
import * as actions from "../redux/actions";
import {useEffect, useState} from "react";
import {Badge, Button, Card, Carousel, Col, Container, Row} from "react-bootstrap";
import '../assets/css/main.css';
import {request_get} from "../api/request";
import {useNavigate} from "react-router-dom";
import {HandIndexThumb, Mouse} from "react-bootstrap-icons";

import {
    GET_BANNERS,
    GET_NEW_PRODUCTS,
    GET_ONSALES_PRODUCTS,
    GET_TOP_PRODUCTS,
    MAIN_URL
} from "../api/url";

export const Main = () => {
    const dispatch = useDispatch();
    const [banners, setBanners] = useState([]);
    const [newsProducts, setNewsProducts] = useState([]);
    const [topsProducts, setTopsProducts] = useState([]);
    const [onsalesProducts, setOnsalesProducts] = useState([]);
    const [bannerLoader, setBannerLoader] = useState(false)
    let navigate = useNavigate();

    useEffect(() => {
        dispatch(actions.showMenuCatalog(false))
        getBanners();
        getNewsProducts()
        getTopsProducts()
        getOnsalesProducts()
    }, [])

    useEffect(() => {

    },[banners])

    const getBanners = async () => {
        console.log(111);
        setBannerLoader(true)
        const response = await request_get(GET_BANNERS);
        setBannerLoader(false)
        if(response.success){
            setBanners(response.data);
        }
    }

    const getNewsProducts =  async () => {
        const response = await request_get(GET_NEW_PRODUCTS);
        if(response.success){
            setNewsProducts(response.data);
        }
    }

    const getTopsProducts =  async () => {
        const response = await request_get(GET_TOP_PRODUCTS);
        if(response.success){
            setTopsProducts(response.data);
        }
    }

    const getOnsalesProducts =  async () => {
        const response = await request_get(GET_ONSALES_PRODUCTS);
        if(response.success){
            setOnsalesProducts(response.data);
        }
    }

    const addToCart = (product_id) => {
        dispatch(actions.cartAdd(product_id))
    }

    const goToProductPage = (path) => {
        navigate(path);
    }

    const goToOneClickPage = (path) => {
        navigate(path);
    }

    return (
        <Container>
            <Row >
                <Col md="12">
                    <Carousel>
                        {banners.length > 0 ?
                            banners.map((value, index) => {
                                return  <Carousel.Item key={index} className="pointer"
                                            onClick={() => goToProductPage('/sub_category/product?product_id='+value.products_id+'&sub_category_id='+value.products.sub_categories[0].id)
                                                }>
                                            <img
                                                className="d-block w-100"
                                                src={MAIN_URL + 'images/banners/'+value.img}
                                                alt="First slide"
                                            />
                                            
                                            
                                        </Carousel.Item>
                            })

                        :null
                        }
                    </Carousel>

                </Col>
                
            </Row>
            <br/>
            <Row>
                <h3>Новинки</h3>
            </Row>
            <Row >
                {
                    newsProducts.length > 0 ?
                        newsProducts.map((value, index) => {
                          return   <Col md="2" key={index} className="mob-card"> 
                                <Card  key={index}  className="card_product">
                                        <Card.Img onClick={() => 
                                            goToProductPage('/sub_category/product?product_id='+value.id+'&sub_category_id='+value.sub_categories[0].id)
                                        }
                                            className="product_img_main" variant="top" src={MAIN_URL + 'images/products/'+value.img} />
                                        <Card.Body>
                                            {value.discount != null ? <Badge bg={"danger"}>{value.discount}%</Badge> : null }

                                            <Card.Title className="card_title"><span className="text_price">{value.price} сом </span>
                                                {value.old_price != null ? <span className="old_price">{value.old_price} сом</span> : null }
                                            </Card.Title>

                                            <Card.Text>
                                                {value.name.length > 40 ? value.name.slice(0,40)+ "..." : value.name}
                                            </Card.Text>
                                            <div className="d-flex justify-content-between">
                                                
                                                    <Button title="Добавить в корзину" onClick={() => addToCart(value.id)} className="addToCard pointer" >В корзину</Button>
                                                    <Button title="Купить в один клик" variant="dark addToCard pointer one-click" onClick={() => goToOneClickPage('/one_click_buy?product_id='+value.id)}>
                                                        <HandIndexThumb/>
                                                    </Button>
                                                
                                                
                                            </div>
                                                
                                        </Card.Body>
                                    </Card>
                            </Col>
                            
                        }) : null
                }
            </Row>
            <br/>
            <Row>
                <h3>Топ продаж</h3>
            </Row>
            <Row xs="auto">
                {
                    topsProducts.length > 0 ?
                        topsProducts.map((value, index) => {
                            return <Col md="2" key={index} className="mob-card"> 
                                <Card  className="card_product">
                                        <Card.Img onClick={() => 
                                            goToProductPage('/sub_category/product?product_id='+value.id+'&sub_category_id='+value.sub_categories[0].id)
                                        }
                                            className="product_img_main" variant="top" src={MAIN_URL + 'images/products/'+value.img} />
                                        <Card.Body>
                                            {value.discount != null ? <Badge bg={"danger"}>{value.discount}%</Badge> : null }

                                            <Card.Title className="card_title"><span className="text_price">{value.price} сом </span>
                                                {value.old_price != null ? <span className="old_price">{value.old_price} сом</span> : null }
                                            </Card.Title>

                                            <Card.Text>
                                                {value.name.length > 40 ? value.name.slice(0,40)+ "..." : value.name}   
                                            </Card.Text>
                                            <div className="d-flex justify-content-between">
                                                
                                                    <Button title="Добавить в корзину" onClick={() => addToCart(value.id)} className="addToCard pointer" >В корзину</Button>
                                                
                                                    <Button title="Купить в один клик" variant="dark addToCard pointer one-click" onClick={() => goToOneClickPage('/one_click_buy?product_id='+value.id)}>
                                                        <HandIndexThumb/>
                                                    </Button>
                                                
                                                
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                    
                        }) : null
                }
            </Row>
            <br/>
            <Row>
                <h3>Распродажа</h3>
            </Row>
            <Row xs="auto">
                {
                    onsalesProducts.length > 0 ?
                        onsalesProducts.map((value, index) => {
                            return   <Col md="2" key={index} className="mob-card"> 
                                        <Card className="card_product">
                                        <Card.Img onClick={() => 
                                            goToProductPage('/sub_category/product?product_id='+value.id+'&sub_category_id='+value.sub_categories[0].id)
                                        }
                                            className="product_img_main" variant="top" src={MAIN_URL + 'images/products/'+value.img} />
                                        <Card.Body>
                                            {value.discount != null ? <Badge bg={"danger"}>{value.discount}%</Badge> : null }

                                            <Card.Title className="card_title"><span className="text_price">{value.price} сом </span>
                                                {value.old_price != null ? <span className="old_price">{value.old_price} сом</span> : null }
                                            </Card.Title>

                                            <Card.Text>
                                                {value.name.length > 40 ? value.name.slice(0,40)+ "..." : value.name}
                                            </Card.Text>
                                            <div className="d-flex justify-content-between">
                                                
                                                    <Button title="Добавить в корзину" onClick={() => addToCart(value.id)} className="addToCard pointer" >В корзину</Button>
                                                
                                                    <Button title="Купить в один клик" variant="dark addToCard pointer one-click" onClick={() => goToOneClickPage('/one_click_buy?product_id='+value.id)}>
                                                        <HandIndexThumb />
                                                    </Button>
                                                
                                                
                                            </div>
                                        </Card.Body>
                                    </Card>
                                    </Col>
                        }) : null
                }
            </Row>
        </Container>
    )
}
