import {Col, Collapse, Container, NavLink, Row} from "react-bootstrap";
import '../../assets/css/catalog.css';

import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../redux/actions';
import {useEffect, useState} from "react";
import {} from 'react-dom';
import {request_get} from "../../api/request";
import {LIST_CATEGORIES, LIST_SUB_CATEGORIES} from "../../api/url";
import {Link} from "react-router-dom";
import {forEach} from "react-bootstrap/ElementChildren";

export const Catalog = () => {
    const show_menu_catalog = useSelector((state) => state.showMenuReducer.show_menu_catalog);

    const [groupCatalog, setGroupCatalog] = useState([]);
    const [subCategories, setSubcategories] = useState([]);

    const [currentSubCategory, setCurrentSubCategory] = useState(0);

    useEffect(() => {
       getCategories()
    }, []);

    const getCategories = async () => {
        try {
            const response = await request_get(LIST_CATEGORIES);
            setGroupCatalog(response)
        } catch (error) {
        }
    };

    const getSubcategories = async (category_id) => {

        if(subCategories.length == 0){
            const response = await request_get(LIST_SUB_CATEGORIES + '/' + category_id);
            let sub_categories = [{
                'category_id' : category_id,
                'data' : []
            }];

            sub_categories[0].data = response

            setSubcategories(sub_categories);
        }else{
            let _subCategories = subCategories;
            let is_exist = false;
            for(let i = 0; i < subCategories.length; i++){
                if(subCategories[i].category_id  == category_id){
                    is_exist = true;
                }else{
                    if(!is_exist){
                        is_exist = false;
                    }
                }
            }

            if(!is_exist){
                const response = await request_get(LIST_SUB_CATEGORIES + '/' + category_id);

                let result = [{
                    'category_id' : category_id,
                    'data' : []
                }];

                result[0].data = response;
                _subCategories.push(result[0]);
                setSubcategories(_subCategories);
            }
        }
        setCurrentSubCategory(category_id);
    }

    useEffect(() => {

    },[currentSubCategory, subCategories])

    const ListSubCategories = () => {
        let _result = [];

        for(let i = 0; i < subCategories.length; i++){
            if(subCategories[i].category_id  == currentSubCategory){
                _result = subCategories[i].data;
            }
        }

        return (
              _result.map((data_value, index_data) => {
                    return <Col key={index_data}>
                                <ul className="list-unstyled ul-margin-top">
                                    <p className="group_name_bold">
                                        {data_value.name}
                                    </p>
                                    <SubGroupCategoryName data={data_value.sub_categories} />
                                </ul>
                            </Col>

                })

        )
    }

    const SubGroupCategoryName = ({data}) => {
        return (
            data.length > 0 ?
                data.map((v, i) => {
                    return <Link key={i} className="link-cart" to={`sub_category?sub_category_id=${v.id}`}>
                                <li className="sub_category" key={i}>
                                    {v.name}
                                </li>
                            </Link>
                })
                :null
        )
    }

    return(
        <>
            {show_menu_catalog == true ?
                <Container className="bg-catalog">
                    <Container>
                        <Row className="">
                            <Col md="3" className="bg-catalog">
                                <ul className="list-unstyled ul-margin-top">
                                    {
                                        groupCatalog.length > 0 ?
                                            groupCatalog.map((value, index) => {
                                              /*  return <Link key={index}  to="/invoices" className="a_catalog_list" onMouseOver={() => getSubcategories(value.id)}>
                                                            <li className="group_menu_list">
                                                                            {value.name}
                                                            </li>
                                                        </Link> */
                                                 return <li key={index} className="group_menu_list" onMouseOver={() => getSubcategories(value.id)}>
                                                                        {value.name}
                                                        </li>
                                            }) : null
                                    }
                                </ul>
                            </Col>
                            <Col md="9">
                                <Container className="container-sub-groups">
                                    <Row>
                                        {
                                            subCategories.length > 0 ?
                                                <ListSubCategories/> : null
                                        }
                                    </Row>
                                </Container>
                            </Col>
                        </Row>
                    </Container>
                </Container>
            : null}
        </>
    )

}
