import {useEffect, useState} from "react";
import {Button, Col, Container, Row, FormLabel} from "react-bootstrap";
import {request_post} from "../api/request";
import {REGISTER_USER} from "../api/url";
import 'react-medium-image-zoom/dist/styles.css'
import { BreadcrumbComponent } from '../component/Partials/Breadcrumb';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const Register = () => {
    const [phoneError, setPhoneError] = useState('')
    const [passwordError, setPasswordError] = useState('')
    const [nameError, setNameError] = useState('')

    const loginInput = {
        phone: "",
        password: "",
        name: "",
        password_confirmation: ""
      };

    const [
        { phone, password, name, password_confirmation },
        setState
    ] = useState(loginInput);
    
    const clearState = () => {
        setState({ ...loginInput });
    };

    const onInputChange = e => {
        const { name, value } = e.target;

        if(e.target.name === 'phone'){
            setPhoneError('')
        }

        if(e.target.name === 'name'){
            setNameError('')
        }

        if(e.target.name === 'password'){
            setPasswordError('')
        }
        
        setState(prevState => ({ ...prevState, [name]: value }));
      };

    useEffect(() => {
        
    }, []);

    const onSubmitRegister = () => {
        //setValidated('was-validated')
        
        (phone) ? setPhoneError('') : setPhoneError('Поле не должно быть пустым');
        (name) ? setNameError('') : setPhoneError('Поле не должно быть пустым');
        (password.length >= 6) ? setPasswordError(''): setPasswordError('Поле пароль не должно быть пустым или меньше 6 символа');
        (password === password_confirmation) ? setPasswordError('') : setPasswordError('Подтверждения пароля не совпадает');
        (phone && password && name && password === password_confirmation) ? register() : toast.error('Заполните форму корректно');
        
    }
    
    const register =  async () => {
        
        const params = {
            phone: phone,
            password: password,
            password_confirmation: password_confirmation,
            name: name
        }

        const response = await request_post(REGISTER_USER, params);

        
        if(response.success){
            //toast.success(response.data)
            setPhoneError('')
            setPasswordError('')
            setNameError('')
            clearState()
            //setValidated([''])
            toast.success('Войдите используя логин и пароль')
        }else{
            
            if(response.message.hasOwnProperty('phone')){
                setPhoneError('Позвователь с именем сушествует')  
            }

            if(response.data.message.hasOwnProperty('password')){
                setPasswordError('Позвователь с именем сушествует')  
            }
            //setPhoneError('Неверный телефон или пароль')
        }        
    }

    return (
        <Container>
            <Row className="mt-3">
                <Col>
                    <BreadcrumbComponent />
                </Col>
            </Row>
            <ToastContainer 
                position="top-right"
                autoClose={4000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme = "colored"
            />
            <form>
            <Row className="justify-content-center">
                
                <Col md="5">
                    <h3>Регистрация</h3>    
                    <div>
                        <FormLabel htmlFor="name" className="form-label mt-3" >
                            Имя <span className="text-danger">*</span>
                        </FormLabel>
                        <input value={name} onChange={onInputChange} name="name"
                            type="text" id="name" 
                            className={nameError ? 'form-control  is-invalid': 'form-control'} placeholder="Введите имя" required/>
                        <div className="valid-feedback">Корректно</div>
                        <div className="invalid-feedback">{nameError}</div>
                    </div>

                    <div>
                        <FormLabel htmlFor="phone" className="form-label mt-3" >
                            Телефон <span className="text-danger">*</span>
                        </FormLabel>
                        <input value={phone} onChange={onInputChange} name="phone"
                            type="text" id="phone" className={phoneError ? 'form-control  is-invalid': 'form-control'} placeholder="Введите номер телефона" required/>
                        <div className="valid-feedback">Корректно</div>
                        <div className="invalid-feedback">{phoneError}</div>
                    </div>

                    <div>
                        <FormLabel htmlFor="password" className="form-label mt-3" >
                            Пароль <span className="text-danger">*</span>
                        </FormLabel>
                        <input value={password} onChange={onInputChange} name="password"
                            type="password" id="password" className={passwordError ? 'form-control  is-invalid': 'form-control'} placeholder="Введите пароль" required/>
                        <div className="valid-feedback">Корректно</div>
                        <div className="invalid-feedback">{passwordError}</div>
                    </div>

                    <div>
                        <FormLabel htmlFor="password_confirmation" className="form-label mt-3" >
                            Подтвердите пароль <span className="text-danger">*</span>
                        </FormLabel>
                        <input value={password_confirmation} onChange={onInputChange} name="password_confirmation"
                            type="password" id="password_confirmation" className="form-control" placeholder="Подтвердите пароль" required/>
                    </div>

                    <div className="mt-3">
                        <Button onClick={onSubmitRegister}>Регистрация</Button>
                    </div>
                    
                    
                </Col>

                
            </Row>

            
            </form>
            
        </Container>
    );
}