import {Col, Container, Image, Row} from "react-bootstrap";
import '../../assets/css/footer.css'
import {Routes, Route, useParams, Link, useNavigate} from "react-router-dom";
import { useState } from "react";
import {RSS_SUBSCRIBE} from "../../api/url";
import {request_post} from "../../api/request";
import { Bell } from "react-bootstrap-icons";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const Footer = () => {
    const [rssEmail, setRssEmail] = useState('');    
    const [rssEmailError, setRssEmailError] = useState('');    

    const sendRss = async (e) => {
        e.preventDefault()
        if(!rssEmail){
            setRssEmailError('Поле не должно быть пустым')
            return
        }
        const params = {
            email: rssEmail,
        }
        const response = await request_post(RSS_SUBSCRIBE, params);

        setRssEmailError('')

        if(response.success)
        {
            setRssEmail('')
            
            toast.success(response.data)

        }else{
            if(response.message.hasOwnProperty('email')){
                toast.error('Вы уже подписаны на наши обновления')
                setRssEmailError('Вы уже подписаны на наши обновления')
            }
            
        }

    }

    return(
        <Container className="bg-footer">
            <br/>
            <Container>
            <ToastContainer 
                position="top-right"
                autoClose={4000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme = "colored"
            />
            <Row>
                <Col md="3">
                    <Image className="img_logo_footer" src="/img/logo1.svg" />
                    <br/>
                    <br/>
                    <p>Адрес г. Душанбе, ул. Саъди Шерози 64<br/>
                     Email: hayas@gmail.com <br/>
                     Tel: +992 901 000 535</p>
                </Col>
                <Col md="9">
                    <Row>
                        <Col md="4" className="col-6">
                            <p className="footer-title-bold">Информация</p>
                            <ul className="list-unstyled">
                                {/*<li className="sub_category">О нас</li>*/}
                                <li className="sub_category">
                                    <Link to="/contacts" className="footer-link">
                                        Контакты
                                    </Link>
                                </li>
                                <li className="sub_category">
                                    <Link to="/faqs" className="footer-link">
                                        ЧаВо
                                    </Link>
                                </li>
                            </ul>
                            
                        </Col>
                        {/*<Col md="3" className="col-6">
                            <p className="footer-title-bold">Помощь</p>
                            <ul className="list-unstyled">

                                <li className="sub_category">Как сделать заказ</li>
                                <li className="sub_category">Доставка</li>
                                <li className="sub_category">Оплата</li>
                            </ul>
                        </Col>*/}
                        <Col md="4" className="col-6">
                            <p className="footer-title-bold">Навигация</p>

                            <ul className="list-unstyled">
                                <li className="sub_category">
                                    <Link to="type_products?type=feature" className="footer-link">
                                        Скоро в продаже
                                    </Link>
                                </li>
                                <li className="sub_category">
                                    <Link to="type_products?type=discount" className="footer-link">
                                        Акции
                                    </Link>
                                </li>
                                <li className="sub_category">
                                    <Link to="type_products?type=bestseller" className="footer-link">
                                        Топ продаж
                                    </Link>
                                </li>
                            </ul>
                        </Col>
                        <Col md="4" className="col-12">
                            <p className="footer-title-bold">Рассылка</p>
                            <form onSubmit={sendRss}>
                                <div className="input-group mb-3">
                                    <input value={rssEmail}  onChange={e => setRssEmail(e.target.value)} required
                                        type="email" className={rssEmailError ? 'form-control  is-invalid' : 'form-control'} id="rssemail" placeholder="Введите email" />
                                    <button className="btn btn-dark" type="submit" id="button-addon2"><Bell /></button>
                                    <div className="invalid-feedback">{rssEmailError}</div>
                                </div>
                            </form>
                        </Col>
                    </Row>
                </Col>
            </Row>
            </Container>
        </Container>
    )

}
