import {Button, ButtonGroup, Container, Form, Image, InputGroup, Nav, Navbar, Badge} from 'react-bootstrap';
import {
    ArrowUp,
    Facebook,
    Instagram,
    List,
    Phone,
    PhoneFill,
    Search, Telephone,
    Twitter,
    Whatsapp,
    X,
    Cart,
    Person,
    BoxArrowInRight,
    ChevronRight
} from "react-bootstrap-icons";
import '../../assets/css/header.css';
import Dropdown from 'react-bootstrap/Dropdown';
import {MAIN_URL, GET_SEARCH_PRODUCTS, CHECK_USER} from "../../api/url";
import {useDispatch, useSelector} from 'react-redux';
import * as actions from '../../redux/actions';
import {Link} from "react-router-dom";
import {useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import {request_get, request_post} from "../../api/request";
//import {request_post} from "../../api/request";
export const Header = () => {
    
    const show_menu_catalog = useSelector((state) => state.showMenuReducer.show_menu_catalog);
    const search_value = useSelector((state) => state.searchProductsReducer.search_products);
    const cart_list = useSelector((state) => state.cartListReducer.cart_list_products);
    const check_user = useSelector((state) => state.loginTokenReducer.check_user_logged);
    const token = useSelector((state) => state.loginTokenReducer.login_token);
    const dispatch = useDispatch();
    const openCatalog = () => {
        dispatch(actions.showMenuCatalog(!show_menu_catalog))
    }
    let navigate = useNavigate();
    const goToLoginMerchantPage = (path) => {
        //console.log(path);
        navigate(path);
    }

    const goToProductPage = (path) => {
        setSearchProducts([]);
        navigate(path);
    }

    const goToSearchPage = (path) => {
        //console.log(path);
        setSearchProducts([]);
        navigate(path);
    }
    useEffect(() => {
        //dispatch(actions.loginCheck(token))
        
        if(search_value){
            getSearchProducts(search_value)
        }else{
            setSearchProducts([])
        }
        
    }, [search_value]);

    useEffect(() => {
        //dispatch(actions.loginCheck(token))
        
        if(token && token != 'null'){
            checkUser(token)
        }
        
        
    }, []);

    const checkUser =  async () => {
        //return new Promise((resolve, reject) => {
            const config = {
                headers: { Authorization: `Bearer ${token}` }
            };
            
            const response = await request_post(CHECK_USER, {}, config);
            //resolve(response)
            
            if(response.success){
                dispatch(actions.loginCheck(response.is_logged))    
                dispatch(actions.isAdmin(response.is_admin))
            }
    }

    const [searchProducts, setSearchProducts] = useState([]);

    const searchPress = (e)  => {
        if(e.charCode === 13){
            goToSearchPage('/search?search='+search_value);
        }
    }

    const getSearchProducts = async (value) =>{
        const response = await request_get(GET_SEARCH_PRODUCTS, {name:search_value}); 
        
        setSearchProducts(response.data)
    }

    return (
        <Container className="bg-light">
            <Navbar bg="light" expand="lg">
                <Container className="m-0 p-0">
                    <Link to="/">
                        <Navbar.Brand >
                            <Image src="/img/logo1.svg"/>
                        </Navbar.Brand>
                    </Link>
                    <Navbar.Toggle aria-controls="basic-navbar-nav"/>

                    <Navbar.Collapse id="basic-navbar-nav">
                        <Container className="margin-out">
                            <Nav className="">
                                <ButtonGroup aria-label="Basic example" className="me-3 catalog-button">
                                    <Button className="text-white noHoverBtn" onClick={() => openCatalog()}
                                            style={{
                                                margin: "0",
                                                padding: "0",
                                                paddingLeft: "10px"
                                            }}>
                                        {
                                            show_menu_catalog == false ?
                                                <List
                                                    color="white"
                                                    size={26}
                                                    style={{position: "relative", top: "-1px"}}/> :
                                                <X color="white" size={26}
                                                    style={{position: "relative", top: "-1px"}}/>
                                        }
                                    </Button>
                                    <Button className="text-white noHoverBtn" onClick={() => openCatalog()}
                                        style={{
                                            margin: "0",
                                            padding: "0",
                                            paddingRight: "10px"
                                        }}>Каталог</Button>
                                </ButtonGroup>
                                
                                <InputGroup className="header-search">
                                    <Form.Control
                                        placeholder="Поиск..."
                                        aria-label="Recipient's username"
                                        aria-describedby="basic-addon2"
                                        className="form-control-md header-search-input"
                                        value={search_value}
                                        onKeyPress={searchPress}
                                        onChange={e => dispatch(actions.searchProducts(e.target.value)) }
                                    />
                                    <Link to={'/search?search='+search_value} className="btn btn-primary pointer header-search-button">
                                    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M8.14269 17.6956C10.1537 17.9261 12.1767 17.4415 13.8605 16.335L18.5117 20.9869C18.5118 20.9869 18.5118 20.9869 18.5119 20.987C18.84 21.3153 19.285 21.4998 19.7492 21.5C20.2134 21.5002 20.6586 21.3159 20.987 20.9877C21.3153 20.6596 21.4998 20.2144 21.5 19.7502C21.5002 19.286 21.3159 18.8408 20.9878 18.5124L20.9877 18.5122L16.3351 13.8589C17.4411 12.1737 17.9246 10.1494 17.6926 8.13759C17.4436 5.97804 16.3884 3.99215 14.7383 2.57723C13.0881 1.1623 10.9646 0.422699 8.79262 0.506402C6.62062 0.590104 4.56032 1.49094 3.02391 3.02866C1.48751 4.56638 0.5883 6.62758 0.506165 8.79989C0.42403 10.9722 1.16503 13.0954 2.58092 14.7448C3.99682 16.3942 5.98321 17.4481 8.14269 17.6956ZM15.5006 9.12352C15.5006 9.9608 15.3357 10.7899 15.0153 11.5634C14.6949 12.337 14.2254 13.0398 13.6334 13.6319C13.0415 14.2239 12.3387 14.6935 11.5653 15.0139C10.7919 15.3343 9.96295 15.4992 9.12582 15.4992C8.28868 15.4992 7.45975 15.3343 6.68633 15.0139C5.91292 14.6935 5.21017 14.2239 4.61822 13.6319C4.02627 13.0398 3.5567 12.337 3.23633 11.5634C2.91597 10.7899 2.75108 9.9608 2.75108 9.12352C2.75108 7.43256 3.42272 5.81086 4.61822 4.61519C5.81372 3.41952 7.43515 2.74781 9.12582 2.74781C10.8165 2.74781 12.4379 3.41952 13.6334 4.61519C14.8289 5.81086 15.5006 7.43256 15.5006 9.12352Z" fill="white" stroke="white"/>
                                    </svg>


                                    </Link>
                                    {
                                        searchProducts.length ? 

                                            <div className="search-group" >
                                                {searchProducts.map((value, key) => {
                                                    return <div className="search-elements p-2  pointer d-flex justify-content-between" key={key} onClick={() => 
                                                        goToProductPage('/sub_category/product?product_id='+value.id+'&sub_category_id='+value.sub_categories[0].id)}>
                                                                <div>
                                                                    <Search />
                                                                </div>
                                                                <div>
                                                                    <span>{value.name}</span>
                                                                </div> 
                                                                <div>
                                                                    <ChevronRight/>
                                                                </div>
                                                                
                                                            </div>
                                                })}    
                                                

                                                
                                            </div>
                                        : null
                                    }
                                    
                                </InputGroup>
                                

                                


                                <div className="d-flex align-items-center header-icons">
                                    <Link to="/carts" className="mob-cart-icon">
                                    
                                    {<span>
                                        
                                        {/*<svg width="31" height="31" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M16.63 17.47l-.6-11a.51.51 0 00-.5-.47h-2v-.43a3.5 3.5 0 00-7 0V6h-2a.51.51 0 00-.5.47l-.62 11a.49.49 0 00.49.53h12.3a.49.49 0 00.43-.53zm-12.31-.42L4.9 7h10.2l.56 10.1-11.34-.05zM7.5 5.57a2.5 2.5 0 115 0V6h-5v-.43z" fill="black"></path>
                                            <text x="50%" y="66%" dominantBaseline="middle" textAnchor="middle" fontWeight="bold" fontSize="9px" width="auto" fill="black">{cart_list.length}</text>
                                        </svg>*/}
                                        {/*<svg width="28" height="28" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M5.00003 4C5.00003 2.93913 5.42146 1.92172 6.1716 1.17157C6.92175 0.421427 7.93916 0 9.00003 0H13C14.0609 0 15.0783 0.421427 15.8284 1.17157C16.5786 1.92172 17 2.93913 17 4V6H21C21.1452 6.00007 21.2886 6.03174 21.4203 6.09281C21.5519 6.15388 21.6688 6.24288 21.7626 6.35365C21.8564 6.46441 21.925 6.59427 21.9636 6.73421C22.0022 6.87415 22.0098 7.02081 21.986 7.164L20.404 16.658C20.2483 17.5916 19.7666 18.4398 19.0444 19.0517C18.3223 19.6636 17.4065 19.9996 16.46 20H5.54003C4.59333 19.9999 3.67735 19.664 2.955 19.052C2.23266 18.4401 1.75076 17.5918 1.59503 16.658L0.014028 7.164C-0.00977814 7.02081 -0.00212431 6.87415 0.036458 6.73421C0.0750403 6.59427 0.143626 6.46441 0.23745 6.35365C0.331275 6.24288 0.448088 6.15388 0.579776 6.09281C0.711464 6.03174 0.854869 6.00007 1.00003 6H5.00003V4ZM7.00003 6H12C12.2652 6 12.5196 6.10536 12.7071 6.29289C12.8946 6.48043 13 6.73478 13 7C13 7.26522 12.8946 7.51957 12.7071 7.70711C12.5196 7.89464 12.2652 8 12 8H2.18003L3.56903 16.329C3.64685 16.7957 3.88758 17.2196 4.24844 17.5255C4.60931 17.8315 5.06694 17.9996 5.54003 18H16.46C16.9333 17.9998 17.3911 17.8318 17.7522 17.5259C18.1133 17.2199 18.3541 16.7958 18.432 16.329L19.82 8H16C15.7348 8 15.4804 7.89464 15.2929 7.70711C15.1054 7.51957 15 7.26522 15 7V4C15 3.46957 14.7893 2.96086 14.4142 2.58579C14.0391 2.21071 13.5304 2 13 2H9.00003C8.4696 2 7.96089 2.21071 7.58582 2.58579C7.21075 2.96086 7.00003 3.46957 7.00003 4V6Z" fill="#0E6DFD"/>
                                            <path d="M16 13C16 15.7614 13.7615 18 11 18C8.2386 18 6.00003 15.7614 6.00003 13C6.00003 10.2386 8.2386 8 11 8C13.7615 8 16 10.2386 16 13Z" fill="#FF0000"/>
                                            <text x="50%" y="70%" dominantBaseline="middle" textAnchor="middle" fontWeight="bold" fontSize="10px" width="auto" fill="white">{cart_list.length}</text>
                                        </svg>*/}
                                        <svg width="26" height="26" fill="black" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M15.55 13c.75 0 1.41-.41 1.75-1.03l3.58-6.49c.37-.66-.11-1.48-.87-1.48H5.21l-.94-2H1v2h2l3.6 7.59-1.35 2.44C4.52 15.37 5.48 17 7 17h12v-2H7l1.1-2h7.45zM6.16 6h12.15l-2.76 5H8.53L6.16 6zM7 18c-1.1 0-1.99.9-1.99 2S5.9 22 7 22s2-.9 2-2-.9-2-2-2zm10 0c-1.1 0-1.99.9-1.99 2s.89 2 1.99 2 2-.9 2-2-.9-2-2-2z"></path>
                                            
                                        </svg>
                                        
                                        
                                        
                                    
                                        
                                            {/*<span className="" >{cart_list.length}</span>*/}
                                
                                        </span>    }
                                    
                                    
                                    
                                        {/*<Badge id="cart-color" 
                                            
                                            >
                                            <Cart
                                                color="#0e6dfd"
                                                size={25}
                                            
                                                className="ms-2 me-2"
                                                
                                            ></Cart>
                                            <span >{cart_list.length}</span>
                            </Badge>*/}
                                        
                                    </Link>
                                    {cart_list.length ?  <span className="cart_text">
                                        {cart_list.length}
                                    </span> : null }
                                    
                                    {/*<Facebook
                                        color="#0d8af0"
                                        size={25}
                                        className="ms-2 me-2"
                                    />
                                    <Instagram
                                        color="#cd3d7b"
                                        size={25}
                                        className="ms-2 me-2"
                                    />
                                    <Twitter
                                        color="#1d9bf0"
                                        size={25}
                                        className="ms-2 me-2"
                                    />
                                    <Whatsapp
                                        color="#1d9bf0"
                                        size={25}
                                        className="ms-2 me-2"
                                    />*/}
                                    <a href="tel:992901000535"  variant="outline-primary" className="ms-2 me-2">
                                        <Telephone
                                            color="black"
                                            size={22}
                                            
                                        />
                                        
                                    </a>
                                    
                                    {!check_user ? 
                                        <Link  className="btn border-dark ms-2 me-2 d-flex text-color align-items-center" to="/login"><BoxArrowInRight /> Вход</Link>
                                    :
                                        <Link className="btn border-dark ms-2 me-2 d-flex text-color align-items-center" to="/profile">
                                            <svg width="16" height="16" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10 10a4 4 0 100-8 4 4 0 000 8zm0-7a3 3 0 110 6 3 3 0 010-6zM10 11c-5 0-7 2-7 7h14c0-5-2-7-7-7zm0 1c4.08 0 5.73 1.33 6 5H4c.27-3.67 1.92-5 6-5z" fill="currentColor"></path></svg>
                                            Профиль
                                        </Link>    
                                    }
                                    
                                    <Dropdown>
                                        
                                        <Dropdown.Toggle variant="d-flex align-items-center border-dark" id="dropdown-basic" >
                                            <svg width="16" height="16" viewBox="0 2 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10 10a4 4 0 100-8 4 4 0 000 8zm0-7a3 3 0 110 6 3 3 0 010-6zM10 11c-5 0-7 2-7 7h14c0-5-2-7-7-7zm0 1c4.08 0 5.73 1.33 6 5H4c.27-3.67 1.92-5 6-5z" fill="currentColor"></path></svg>
                                            Продавцы
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu className="drop-menu">
                                            <Dropdown.Item href={MAIN_URL+'/login/seller'} target="_blank">
                                                Я продавец
                                            </Dropdown.Item>
                                            <Dropdown.Item  onClick={() => goToLoginMerchantPage('/login-merchant')} >
                                                Стать продавцом
                                            </Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </Nav>
                        </Container>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
                
        </Container>
    )
}
