import {Button, Col, Container, Row, FormLabel} from "react-bootstrap";
import 'react-medium-image-zoom/dist/styles.css'
import { BreadcrumbComponent } from '../component/Partials/Breadcrumb';
import {useState} from "react";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {request_post} from "../api/request";
import {SEND_MESSAGE} from "../api/url";

export const Contacts = () => {
    
    const loginInput = {
        name: "",
        email: "",
        theme: "",
        message: "",
    };


    const [nameError, setNameError] = useState('')
    const [emailError, setEmailError] = useState('')
    const [themeError, setThemeError] = useState('')
    const [messageError, setMessageError] = useState('')

    const [
        { name, email, theme, message },
        setState
    ] = useState(loginInput);
    
    const clearState = () => {
        setState({ ...loginInput });
    };

    const onInputChange = e => {
        const { name, value } = e.target;

        if(e.target.name === 'name'){
            setNameError('')
        }

        if(e.target.email === 'email'){
            setEmailError('')
        }

        if(e.target.theme === 'theme'){
            setThemeError('')
        }

        if(e.target.message === 'message'){
            setMessageError('')
        }

        setState(prevState => ({ ...prevState, [name]: value }));
    };

    const onSubmitSend = (e) => {
        
        e.preventDefault();
        
        (name) ? setNameError('') : setNameError('Поле не должно быть пустым');
        (email) ? setEmailError(''): setEmailError('Поле пароль не должно быть пустым');
        (theme) ? setThemeError(''): setThemeError('Поле пароль не должно быть пустым');
        (message) ? setMessageError(''): setMessageError('Поле пароль не должно быть пустым');
        (name && email && theme && message ) ? send() : toast.error('Заполните форму корректно');
        
    }

    const send = async () => {
        const params = {
            name: name,
            email: email,
            theme: theme,
            message: message
        }

        const response = await request_post(SEND_MESSAGE, params);

        if(response.success){
            //toast.success(response.data)
            setNameError('')
            setEmailError('')
            setThemeError('')
            setMessageError('')
            clearState()

            toast.success(response.data)
            //setValidated([''])
        }        
    }
    
    return (
        <Container>
            <Row className="mt-3">
                <Col>
                    <BreadcrumbComponent />
                </Col>
            </Row>
            
            <Row className="">
                <Col md="6">
                    <h3>Контакты</h3>
                    <p className="text-secondary">Адрес г. Душанбе, ул. Саъди Шерози 64</p>
                    <p className="text-secondary">Email: faradshophelp@gmail.com</p>
                    <p className="text-secondary">Tel: +992 90 100 05 35</p>
                </Col>
                <Col md="6">
                    <h3>Режим работы</h3>
                    <p className="text-secondary">Понедельник — Пятница: с 9:00 по 17:00</p>
                    <p className="text-secondary">Суббота - Воскресенье: с 11:00 по 14:00</p>
                </Col>
                <Col md="6">
                    <h3>Напишите нам</h3>
                    <form onSubmit={onSubmitSend}>
                        <FormLabel htmlFor="name" className="form-label mt-3" >
                            Ваше имя <span className="text-danger">*</span>
                        </FormLabel>
                        <input value={name} onChange={onInputChange} type="text" id="name" name="name" 
                            className={nameError ? "is-invalid form-control" : "form-control"} placeholder="Введите имя" required/>
                        
                        <div className="invalid-feedback">{nameError}</div>

                        <FormLabel htmlFor="email" className="form-label mt-3" >
                            Ваш email <span className="text-danger">*</span>
                        </FormLabel>
                        <input value={email} onChange={onInputChange} type="email" id="email" name="email" 
                            className={emailError ? "is-invalid form-control" : "form-control"} placeholder="Введите email" required/>
                        
                        <div className="invalid-feedback">{emailError}</div>

                        <FormLabel htmlFor="theme" className="form-label mt-3" >
                            Ваш email <span className="text-danger">*</span>
                        </FormLabel>
                        <input value={theme} onChange={onInputChange} type="text" id="theme" name="theme" 
                            className={themeError ? "is-invalid form-control" : "form-control"} placeholder="Введите тему сообщение" required/>
                        
                        <div className="invalid-feedback">{themeError}</div>

                        <FormLabel htmlFor="message" className="form-label mt-3" >
                            Сообщение <span className="text-danger">*</span>
                        </FormLabel>
                        <textarea required value={message} onChange={onInputChange} id="theme" name="message"
                            type="text" className={messageError ? 'form-control is-invalid' : 'form-control'} rows="6" >

                        </textarea>
                        <div className="invalid-feedback">{messageError}</div>

                        <Button type="submit" className="mt-3">Отправить</Button>
                    </form>
                </Col>
            </Row>
        </Container>
    );
}