import {combineReducers} from 'redux';
import showMenuReducer from './showMenuReducer';
import cartListReducer from './cartListReducer';
import searchProductsReducer from './searchProductsReducer';
import loginTokenReducer from './loginTokenReducer';

export default combineReducers({
    showMenuReducer,
    cartListReducer,
    searchProductsReducer,
    loginTokenReducer
});
