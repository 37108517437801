import {Link, useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import * as actions from "../redux/actions";
import {Badge, Button, Card, Col, Container, Row} from "react-bootstrap";
import {request_get} from "../api/request";
import { MAIN_URL, GET_SEARCH_PRODUCTS } from "../api/url";
import Pagination from "react-js-pagination";
import { useSearchParams  } from "react-router-dom";
import { BreadcrumbComponent } from '../component/Partials/Breadcrumb';

export const SearchProducts = () => {
    
    const [searchParams, setSearchParams] = useSearchParams();
    let search = searchParams.get("search");
    let navigate = useNavigate();

    const dispatch = useDispatch();
    const show_menu_catalog = useSelector((state) => state.showMenuReducer.show_menu_catalog);
    //const cart_add = useSelector((state) => state.cartListReducer.cart_list_products);

    const [listProducts, setListProducts] = useState([]);

    const [paginateResponse, setPaginateResponse] = useState('');

    useEffect(() => {
        dispatch(actions.showMenuCatalog(false))
        dispatch(actions.searchProducts(search))
        getProductsBySubCategory();
    }, [search]);

    useEffect(() => {

    }, []);

    const getProductsBySubCategory =  async (pageNumber = 1) => {
        dispatch(actions.showMenuCatalog(false))
        const response = await request_get(GET_SEARCH_PRODUCTS+'?page=' + pageNumber, {name:search}); 
        setListProducts(response.data);
        setPaginateResponse(response);
    }

    const goToProductPage = (path) => {
        navigate(path);
    }

    const addToCart = (product_id) => {
        dispatch(actions.cartAdd(product_id))
    }

    const goToOneClickPage = (path) => {
        navigate(path);
    }
    
    return (
        <Container>
            <Row className="mt-3">
                <Col>
                    <BreadcrumbComponent />
                </Col>
            </Row>

            <Row >
                
                <Col md="12">
                    <Row >
                    {
                        listProducts.length > 0 ?
                            listProducts.map((value, index) => {
                                    return  <Col md="2" key={index} className="mob-card"> 
                                    <Card className="card_product " >
                                    <Card.Img className="img-fluid" onClick={() => 
                                        goToProductPage('/sub_category/product?product_id='+value.id+'&sub_category_id='+value.sub_categories[0].id)
                                        } 
                                        className="product_img_main" variant="bottom" src={MAIN_URL + 'images/products/'+value.img} />
                                    <Card.Body>
                                    <Link className="link-cart" to={'/sub_category/product?product_id='+value.id+'&sub_category_id='+value.sub_category_id}>
                                        {value.discount != null ? <Badge bg={"danger"}>{value.discount}%</Badge> : null }

                                        <Card.Title className="card_title"><span className="text_price">{value.price} сом </span>
                                            {value.old_price != null ? <span className="old_price">{value.old_price} сом</span> : null }
                                        </Card.Title>

                                        <Card.Text>
                                            {value.name.length > 40 ? value.name.slice(0,40)+ "..." : value.name}
                                        </Card.Text>
                                        
                                        </Link>
                                        
                                        
                                    </Card.Body>
                                    <div className="d-flex justify-content-between">
                                        <div>
                                            <Button onClick={() => addToCart(value.id)} className="addToCard pointer" >В корзину</Button>
                                        </div>
                                        <div>
                                            <Button variant="dark addToCard pointer one-click" onClick={() => goToOneClickPage('/one_click_buy?product_id='+value.id)}>
                                                В 1 клик
                                            </Button>
                                        </div>
                                        
                                    </div>
                                </Card></Col>
                                

                            }) : null
                    }
                    </Row>
                </Col>
            </Row>

            <Row xs="auto" className="d-flex justify-content-center">
                <Col>
                <Pagination
                    activePage={paginateResponse.current_page ? paginateResponse.current_page : 0}
                    itemsCountPerPage={paginateResponse.per_page ? paginateResponse.per_page : 0 }
                    totalItemsCount={paginateResponse.total ? paginateResponse.total : 0}
                    onChange={(pageNumber) => {
                        getProductsBySubCategory(pageNumber)
                    }}
                    pageRangeDisplayed={14}
                    itemClass="page-item"
                    linkClass="page-link"
                    firstPageText="Пред. страница"
                    lastPageText="След. страница"
                />
                </Col>
            </Row>

        </Container>
    );
}
