import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import * as actions from "../redux/actions";
import {Button, Card, Col, Container, Row} from "react-bootstrap";
import {request_get} from "../api/request";
import {GET_CART_PRODUCTS, MAIN_URL} from "../api/url";
import 'react-medium-image-zoom/dist/styles.css'
import { BreadcrumbComponent } from '../component/Partials/Breadcrumb';
import { Trash, Plus, Dash } from "react-bootstrap-icons";

export const Carts = () => {
    
    const [cartProducts, setCartProducts] = useState([]);
    const cartList = useSelector((state) => state.cartListReducer.cart_list_products);
    const dispatch = useDispatch();
    //const [params, setParams] = useState({product_ids:cart.cart})
    
    useEffect(() => {
        

    }, []);

    useEffect(() => {
        
        if(cartList.length){
            getCartProducts(cartList)
        }else{
            setCartProducts([])
        }
        
    }, [cartList]);

    const getCartProducts =  async (ids) => {
        
        const response = await request_get(GET_CART_PRODUCTS, {cart: ids.sort()}); 
        setCartProducts(response.data);
        
    }

    //const {addToCart, removeFromCart, removeSameCart} = useContext(Context);
    const addToCart = (product_id) => {
        dispatch(actions.cartAdd(product_id))
    }

    const removeSameCart = (product_id) => {
        
        dispatch(actions.cartRemoveSame(product_id))
    }

    const removeFromCart = (product_id) => {
        
        dispatch(actions.cartRemoveOne(product_id))
    }
    //const [total, setTotal] = useState(0);

    return (
        <Container>
            <Row className="mt-3">
                <Col>
                    <BreadcrumbComponent />
                </Col>
            </Row>

            <Row>
                <Col md="7">
                    <h3>Корзина</h3>
                    <Row>

                    
                
                {
                    cartProducts.length > 0 ?
                        cartProducts.map((value, index) => {
                            
                                return  <Col md="12" key={index} className="mob-card">
                                <Card className=" mb-2" >
                                <Card.Body >
                                <div className="row mb-1 d-flex justify-content-between align-items-center m-2">
                                    <div className="col-md-2 col-lg-2 col-xl-2 ">
                                        <img 
                                        src={MAIN_URL + 'images/products/'+value.img}
                                        className="img-fluid rounded-3" alt="Cotton T-shirt" />
                                    </div>
                                    <div className="col-md-3 col-lg-3 col-xl-3 d-flex justify-content-center mt-2 ">
                                        <h6 className="text-black mb-0">{value.name}</h6>
                                    </div>
                                    <div className="col-md-3 col-lg-3 col-xl-3 d-flex mt-2">
                                        <button title="Удалить" className="btn btn-link px-2" onClick={() => removeFromCart(value.id)}>
                                            <Dash size={26}> </Dash>
                                        </button>
        
                                        <div className="form-control form-control-sm d-flex justify-content-center">
                                            <span className="mt-1" >{cartList.filter(function(el) { return el === value.id }).length}</span>
                                        </div>
                                    
        
                                        <button title="Добавить" className="btn btn-link px-2" onClick={() => addToCart(value.id)}>
                                            <Plus size={26}></Plus>   
                                        </button>
                                    </div>
                                    <div className="col-md-3 col-lg-2 col-xl-2 offset-lg-1 ml-0 d-flex justify-content-center mt-2" >
                                        <span className="mb-0 text_price">
                                            {
                                                'Цена: ' + (value.price * value.count).toFixed(2) 
                                            }
                                            
                                        </span>
                                    </div>
                                    <div className="col-md-1 col-lg-1 col-xl-1 text-end">
                                        <Button variant="danger cart-delete-btn" onClick={() => removeSameCart(value.id)}>
                                            <Trash title="Удалить из корзины" className="pointer"></Trash>
                                        </Button>
                                        
                                    </div>
                                </div>
                                </Card.Body>
                            </Card>   
                                </Col>
                                
                        }) : <div className="d-flex justify-content-center m-5">
                                <h3>Ваша корзина пуста </h3>
                            </div> 
                    }
                    { cartProducts.length > 0 ?
                        <div className="mt-2 d-flex justify-content-between">
                            <h4 >Сумма: <span className="text_price"> {cartProducts.reduce((accumulator, object) => 
                                accumulator + parseFloat(object.price) * object.count , 0  
                            ).toFixed(2) + ' '} 
                             TJS </span></h4>
                            <Link className="btn btn-dark pointer" to="/orders" >Оформить заказ </Link>
                        </div>
                        :null
                    }
                    </Row>
                </Col>
            </Row>
        </Container>
    );
}