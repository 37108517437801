import {useEffect, useState} from "react";
import {Button, Col, Container, Row, FormLabel} from "react-bootstrap";
import {request_post} from "../api/request";
import {LOGIN_MERCHANT} from "../api/url";
import 'react-medium-image-zoom/dist/styles.css'
import { BreadcrumbComponent } from '../component/Partials/Breadcrumb';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {useDispatch} from "react-redux";
import * as actions from "../redux/actions";

export const LoginMerchant = () => {
    const dispatch = useDispatch();
    const who = 'merchant'
    const [phoneError, setPhoneError] = useState('')
    const [nameError, setNameError] = useState('')
    const [emailError, setEmailError] = useState('')
    const [passportError, setPassportError] = useState('')
    const [companyError, setCompanyError] = useState('')
    const [siteUrlError, setSiteUrlError] = useState('')
    //const [phoneError, setPhoneError] = useState('')
    const [passport, setPassport] = useState(null)

    const loginInput = {
        phone: "",
        name: "",
        site_url: "",
        email: "",
        company: "",
      };

    const [
        { phone, name, company, email, site_url},
        setState
    ] = useState(loginInput);
    
    const clearState = () => {
        setState({ ...loginInput });
    };

    const onFileChange = event => { 
        // Update the state 
        setPassport(event.target.files[0]); 
      }; 

    const onInputChange = e => {
        const { name, value } = e.target;

        if(e.target.name === 'phone'){
            setPhoneError('')
        }

        if(e.target.name === 'name'){
            setNameError('')
        }

        if(e.target.name === 'site_url'){
            setSiteUrlError('')
        }

        if(e.target.name === 'company'){
            setCompanyError('')
        }

        if(e.target.name === 'email'){
            setEmailError('')
        }
        
        setState(prevState => ({ ...prevState, [name]: value }));
      };

    useEffect(() => {
    }, []);

    useEffect(() => {
        dispatch(actions.showMenuCatalog(false))    
    }, []);
    

    const onSubmitLogin = (e) => {
        //setValidated('was-validated')
        e.preventDefault()
        const formData = new FormData();
        formData.append('passport', passport);
        formData.append('phone', phone);
        formData.append('name', name);
        formData.append('email', email);
        formData.append('company', company);
        formData.append('site_url', site_url);
        formData.append('who', who);
        /*const params = {
            phone: phone,
            name: name,
            email: email,
            company: company,
            site_url: site_url,
            passport: passport,
            who: who
        }

        formData.append(params);*/
        const config = {
            headers: {
              'content-type': 'multipart/form-data',
            },
        };
        
        (name) ? setNameError('') : setNameError('Поле не должно быть пустым');
        (phone) ? setPhoneError('') : setPhoneError('Поле не должно быть пустым');
        (site_url) ? setSiteUrlError('') : setSiteUrlError('Поле не должно быть пустым');
        (company) ? setCompanyError('') : setCompanyError('Поле не должно быть пустым');
        //(passport) ? setPassportError('') : setPassportError('Поле не должно быть пустым');
        (email) ? setEmailError('') : setEmailError('Поле не должно быть пустым');

        (phone, name, site_url, company, email) ? login(formData, config) : toast.error('Заполните форму корректно');
        
    }
    
    const login =  async (formData, config) => {
        
        const response = await request_post(LOGIN_MERCHANT, formData, config);

        if(response.success){
            toast.success(response.data)
            setPhoneError('')
            setNameError('')
            setEmailError('')
            setSiteUrlError('')
            setCompanyError('')
            setPassportError('')
            clearState()
            setPassport(null)
            
            //setValidated([''])
        }else{
            if(response.message.hasOwnProperty('emeil')){
                setEmailError('Этот Email уже зарегистрирован')
            }
            
        }        
    }

    return (
        <Container>
            <Row className="mt-3">
                <Col>
                    <BreadcrumbComponent />
                </Col>
            </Row>
            <ToastContainer 
                position="top-right"
                autoClose={4000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme = "colored"
            />
            <form onSubmit={onSubmitLogin}>
            <Row className="justify-content-center">
                
                <Col md="5">
                    <h3>Заявка</h3>    
                    <div>
                        <FormLabel htmlFor="phone" className="form-label mt-3" >
                            Имя <span className="text-danger">*</span>
                        </FormLabel>
                        <input value={name} onChange={onInputChange} name="name"
                            type="text" id="name" className={nameError ? 'form-control  is-invalid': 'form-control'} 
                            placeholder="Введите имя" required/>
                        <div className="valid-feedback">Корректно</div>
                        <div className="invalid-feedback">{nameError}</div>
                    </div>

                    <div>
                        <FormLabel htmlFor="phone" className="form-label mt-3" >
                            Email <span className="text-danger">*</span>
                        </FormLabel>
                        <input value={email} onChange={onInputChange} name="email"
                            type="email" id="email" className={emailError ? 'form-control  is-invalid': 'form-control'} 
                            placeholder="Введите email" required/>
                        <div className="valid-feedback">Корректно</div>
                        <div className="invalid-feedback">{emailError}</div>
                    </div>

                    <div>
                        <FormLabel htmlFor="company" className="form-label mt-3" >
                            Компание <span className="text-danger">*</span>
                        </FormLabel>
                        <input value={company} onChange={onInputChange} name="company"
                            type="text" id="company" className={companyError ? 'form-control  is-invalid': 'form-control'} 
                            placeholder="Введите название компании" required/>
                        <div className="valid-feedback">Корректно</div>
                        <div className="invalid-feedback">{companyError}</div>
                    </div>

                    <div>
                        <FormLabel htmlFor="site_url" className="form-label mt-3" >
                            Ссылка на сайт <span className="text-danger">*</span>
                        </FormLabel>
                        <input value={site_url} onChange={onInputChange} name="site_url"
                            type="text" id="site_url" className={siteUrlError ? 'form-control  is-invalid': 'form-control'} 
                            placeholder="Введите название сайта компании" required/>
                        <div className="valid-feedback">Корректно</div>
                        <div className="invalid-feedback">{siteUrlError}</div>
                    </div>

                    <div>
                        <FormLabel htmlFor="phone" className="form-label mt-3" >
                            Телефон (9 цифр) <span className="text-danger">*</span>
                        </FormLabel>
                        <input value={phone} onChange={onInputChange} name="phone"
                            type="text" id="phone" className={phoneError ? 'form-control  is-invalid': 'form-control'} placeholder="Введите номер телефона" required/>
                        <div className="valid-feedback">Корректно</div>
                        <div className="invalid-feedback">{phoneError}</div>
                    </div>

                    <div>
                        <FormLabel htmlFor="phone" className="form-label mt-3" >
                            Паспорт (формат: jpg, png) <span className="text-danger">*</span>
                        </FormLabel>
                        <input onChange={onFileChange} name="passport"
                            type="file" id="passport" className={passportError ? 'form-control  is-invalid': 'form-control'} />
                        <div className="valid-feedback">Корректно</div>
                        <div className="invalid-feedback">{passportError}</div>
                    </div>

                    <div className="mt-3">
                        <Button type="submit">Отправить заявку</Button>
                    </div>
                    
                    
                </Col>
                
            </Row>

            </form>
            
        </Container>
    );
}