import {LOGIN_TOKEN, CHECK_USER_LOGGED, LOGOUT, IS_ADMIN} from './constants';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {CHECK_USER} from "../api/url";
import {request_post} from "../api/request";

const items = localStorage.getItem('login-token') || null;

const initialState = {
    login_token: items,
    check_user_logged: false,
    is_admin: false
};

const loginTokenReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOGIN_TOKEN:
            
            localStorage.setItem('login-token', action.payload)
            /*return*/ state =  {
                ...state,
                login_token: action.payload,
                check_user_logged: true,
            };
            
            return state;
            //break;
            
        case CHECK_USER_LOGGED:
    
            
            /*state = {
                ...state,
                check_user_logged: action.payload,
            };*/
            
                    //resolve(response)
            state = {
                ...state,
                check_user_logged: action.payload
            };
                
            
            return state;

        case IS_ADMIN:
            
            state = {
                ...state,
                is_admin: action.payload,
            };

            return state;
        case LOGOUT:
            localStorage.setItem('login-token', null)    

            state = {
                ...state,
                login_token: null,
                check_user_logged: false,
                is_admin: false,
            };
            return state;
            //break;
        default:
            return state;
            //break;
    }
    
    //return state;
    
};

const checkUser = async  (token) => {
    
    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };
    
    const response = await request_post(CHECK_USER, {}, config);
    
    
    
    return loginTokenReducer(initialState, {type: CHECK_USER_LOGGED, payload: response})
    
}

export default loginTokenReducer;
