import {React, Fragment, useEffect, useState} from "react";
import { NavLink, useSearchParams  } from "react-router-dom";
import useBreadcrumbs from 'use-react-router-breadcrumbs';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import {GET_SUBCATEGORYNAME_BY_ID, GET_PRODUCTNAME_BY_ID} from "../../api/url";
import {request_get} from "../../api/request";


export const BreadcrumbComponent = () => {
    const [categoryName, setCategoryName] = useState("");
    const [productName, setProductName] = useState("");

    const [searchParams, setSearchParams] = useSearchParams();


    useEffect(() => {
        getSubCategoryNameById();
        getProductNameById();
    },[categoryName, searchParams, productName]);


    const getProductNameById = async () => {
        let product_id = searchParams.get("product_id");

        if(!product_id)
            return;

        const response = await request_get(GET_PRODUCTNAME_BY_ID + '/' + product_id);
        if(response.success)
        {
            setProductName(response.data)
        }
    }

    const getSubCategoryNameById = async () => {
        let sub_category_id = searchParams.get("sub_category_id");

        if(!sub_category_id)
            return;

        const response = await request_get(GET_SUBCATEGORYNAME_BY_ID + '/' + sub_category_id);
        if(response.success)
        {
            setCategoryName(response.data)
        }
    }

    const getNameTypeProducts =  () => {
        let type = searchParams.get("type");
        switch (type){
            case 'feature':
                return 'Скоро в продаже';
            case 'discount':
                return 'Акции';
            case 'bestseller':
                return 'Топ продаж';
        }
        
    }

    const nameSubCategoryById = ({ match }) => {
        return categoryName;
    }

    const nameProductById = ({ match }) => {
        return productName;
    }


    const routes = [
        { path: '/', breadcrumb: 'Главная' },
        { path: '/sub_category', breadcrumb: nameSubCategoryById },
        { path: '/sub_category/product', breadcrumb: nameProductById },
        { path: '/carts', breadcrumb: 'Корзина' },
        { path: '/search', breadcrumb: 'Поиск' },
        { path: '/orders', breadcrumb: 'Заказы' },
        { path: '/login', breadcrumb: 'Вход' },
        { path: '/login-merchant', breadcrumb: 'Заявка' },
        { path: '/register', breadcrumb: 'Регистрация' },
        { path: '/profile', breadcrumb: 'Профиль' },
        { path: '/type_products', breadcrumb: getNameTypeProducts },
        { path: '/faqs', breadcrumb: 'Часто задаваемые вопросы' },
    ];

    const breadcrumbs = useBreadcrumbs(routes);
    
    return (
        <Breadcrumb>
            {
                breadcrumbs.map(({location, match, breadcrumb}, index) => {
                    if(window.location.pathname == match.pathname){
                        return  <Breadcrumb.Item key={index} active to={match.pathname + '' + location.search} href={match.pathname + '' + location.search}>
                                    {breadcrumb}
                                </Breadcrumb.Item>
                        }else{
                            return <Breadcrumb.Item key={index} to={match.pathname + '' + location.search} href={match.pathname + '' + location.search}>
                                        {breadcrumb}
                                    </Breadcrumb.Item>
                        }
                })
            }
        </Breadcrumb>
    );
}