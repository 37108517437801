//import AsyncStorage from '@react-native-community/async-storage';
import axios from 'axios';
import {MAIN_URL} from './url';
import { ToastContainer, toast } from 'react-toastify';

/*export const request_get = async (url) => {
  try {
    const access_token = await AsyncStorage.getItem('access_token');
    const response = await axios.get(BASE_API_URL + url, {headers: {'Authorization': access_token, 'Accept': 'application/json'}});
    return response.data;
  } catch (error) {
     // console.log(error);
  }
};*/

export const request_get = async (url, options = {}) => {
  try {
    const response = await axios.get(MAIN_URL + url, {params: options} );
    return response.data;
  } catch (error) {
    // console.log(error);
  }
};

export const request_post = async (url, data = {}, config = {}) => {
  try {
    const response = await axios.post(MAIN_URL + url, data, config);
    return response.data;
  } catch (error) {
    
    toast.error(error.message)
  }
};

export const request_delete = async (url, data = {}, config = {}) => {
  try {
    const response = await axios.delete(MAIN_URL + url, data, config);
    return response.data;
  } catch (error) {
    // console.error(error);
  }
};
