export const MAIN_URL = process.env.REACT_APP_API_URL;

export const LIST_CATEGORIES = 'api/categories';
export const LIST_SUB_CATEGORIES = 'api/sub_categories';
export const GET_BANNERS = 'api/banners';
export const GET_NEW_PRODUCTS = 'api/news/products';
export const GET_TOP_PRODUCTS = 'api/tops/products';
export const GET_ONSALES_PRODUCTS = 'api/onsales/products';
export const GET_SUB_PRODUCTS = 'api/sub_products_paginate';
export const GET_PRODUCT_DETAILS_BY_ID = 'api/products-details';
export const GET_SUBCATEGORYNAME_BY_ID = 'api/sub_category_name_by_id';
export const GET_PRODUCTNAME_BY_ID = 'api/product_name_by_id';
export const GET_MIN_MAX = 'api/product_min_max_prices';
export const GET_PRODUCTS_BRANDS = 'api/product_brands';
export const GET_PRODUCTS_DETAILS = 'api/product_details';
export const GET_CART_PRODUCTS = 'api/cart_products';
export const CREATE_ORDER = 'api/create_order';
export const GET_SEARCH_PRODUCTS = 'api/products/search';
export const LOGIN_USER = 'api/login';
export const LOGIN_MERCHANT = 'api/appeal';
export const REGISTER_USER = 'api/register-verify';
export const CREATE_COMMENT = 'api/comment-create';
export const CHECK_USER = 'api/check-user';
export const USER_INFO = 'api/user-info';
export const USER_UPDATE = 'api/user-update';
export const USER_LOGOUT = 'api/user-logout';
export const GET_TYPE_PRODUCTS = 'api/products_type'
export const RSS_SUBSCRIBE = 'api/rss_send'
export const SEND_MESSAGE = 'api/send'
export const MARK_PRODUCT = 'api/mark-product'
export const COMMENT_DELETE= 'api/comment-delete'

